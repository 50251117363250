import { gql } from "@apollo/client"

export const meGQL = gql`
    query me {
        me {
            __typename
            firstName
            lastName
            emailAddress
            id
        }
    }
`

export const allMembersGQL = gql`
    query members {
        members {
            dateOfBirth
            emailAddress
            firstName
            id
            lastName
            phoneNumber
            insurances {
                id
                parameters {
                    startDate
                    endDate
                    homeCountry {
                        name
                    }
                }
            }
        }
    }
`

export const drwalterSyncQueueGQL = gql`
    query drwalterSyncQueue {
        drwalterSyncQueue {
            dateOfBirth
            emailAddress
            firstName
            id
            lastName
            phoneNumber
        }
    }
`
export const insuranceQuotesWithPendingProviderDecisionGQL = gql`
    query insuranceQuotesWithPendingProviderDecision {
        insuranceQuotesWithPendingProviderDecision {
            id
            user {
                firstName
                lastName
                emailAddress
            }
            insurance {
                parameters {
                    homeCountry {
                        name
                        code
                    }
                }
            }
            subject {
                ... on InsuranceSubjectHomeCountryChange {
                    __typename
                }
                ... on InsuranceSubjectNewInsurance {
                    __typename
                }
                ... on InsuranceSubjectRegionChange {
                    __typename
                }
            }
        }
    }
`
