import { gql } from "@apollo/client"


export const changeMemberEmailAddressGQL = gql`
    mutation changeMemberEmailAddress($input: ChangeMemberEmailAddressInput!) {
        changeMemberEmailAddress(input: $input) {
            ... on ChangeMemberEmailAddressOutputChanged {
                __typename
            }
        }
    }
`
