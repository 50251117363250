import { gql } from "@apollo/client"

export const drwalterSyncTaskGQL = gql`
    query drwalterSyncTask($memberId: ID!) {
        drwalterSyncTask(memberId: $memberId) {
            ... on DrwalterSyncTaskAddInsurance {
                genkiEffectiveDate
                genkiInsurance {
                    id
                }
                homeCountry {
                    code
                    name
                }
                includeCanadaAndUSA
                period
                person {
                    firstName
                    lastName
                    dateOfBirth
                    emailAddress
                    id
                }
                plan
            }
            ... on DrwalterSyncTaskAddPerson {
                firstName
                lastName
                dateOfBirth
                emailAddress
                genkiMember {
                    id
                }
            }
            ... on DrwalterSyncTaskProvideInsuranceCertificate {
                insurance {
                    id
                }
                agreement{
                    id
                }
                person {
                    firstName
                    lastName
                    dateOfBirth
                    emailAddress
                }
            }
            ... on DrwalterSyncTaskUpdateInsurance {
                current {
                    id
                    homeCountry {
                        code
                        name
                    }
                    period
                    genkiEffectiveDate
                    genkiInsurance {
                        id
                    }
                }
                includeCanadaAndUSA
                period
                plan
                homeCountry {
                    code
                    name
                }
                person {
                    firstName
                    lastName
                    emailAddress
                }
            }
            ... on DrwalterSyncTaskUpdatePerson {
                current {
                    emailAddress
                    id
                    genkiMember {
                        id
                    }
                }
                firstName
                lastName
                dateOfBirth
                emailAddress
            }
            ... on DrwalterSyncTaskWithdrawInsurance {
                insurance {
                    id
                    homeCountry {
                        code
                        name
                    }
                    period
                    genkiEffectiveDate
                    genkiInsurance {
                        id
                    }
                    plan
                    region
                }
                person {
                    firstName
                    lastName
                    emailAddress
                }
            }
        }
    }
`

export const insuranceGQL = gql`
    query insurance($id: ID!) {
        insurance(id: $id) {
            parameters {
                endDate
                homeCountry {
                    code
                    name
                }
            }
        }
    }
`
export const registerDrwalterInsuranceGQL = gql`
    mutation registerDrwalterInsurance($input: RegisterDrwalterInsuranceInput!) {
        registerDrwalterInsurance(input: $input) {
            __typename
        }
    }
`
export const registerDrwalterPersonGQL = gql`
    mutation registerDrwalterPerson($input: RegisterDrwalterPersonInput!) {
        registerDrwalterPerson(input: $input) {
            __typename
        }
    }
`
export const prepareUploadGQL = gql`
    mutation prepareUpload($input: PrepareUploadInput!) {
        prepareUpload(input: $input) {
            __typename
            ... on PrepareUploadOutputPrepared {
                url
            }
        }
    }
`

export const completeUploadGQL = gql`
    mutation completeUpload($input: CompleteUploadInput!) {
        completeUpload(input: $input) {
            __typename
            ... on CompleteUploadOutputCompleted {
                upload {
                    id
                   
                }
            }
        }
    }
`
export const provideDrwalterInsuranceCertificateGQL = gql`
mutation provideDrwalterInsuranceCertificate($input: ProvideDrwalterInsuranceCertificateInput!) {
    provideDrwalterInsuranceCertificate(input: $input) {
        __typename
        ... on ProvideDrwalterInsuranceCertificateOutputProvided {
            __typename
        }
    }
}
`
