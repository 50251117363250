import { useQuery } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { DataTable, DataTableContent } from "@rmwc/data-table"
import { List, ListItem, ListItemText } from "@rmwc/list"
import "@rmwc/typography/styles"
import { useTranslation } from "react-i18next"
import { Link, useParams } from "react-router-dom"
import { InsuranceQuery, InsuranceQueryVariables } from "../../generated"
import { BreadCrumb } from "../shared/BreadCrumbs"
import { assert } from "../shared/Error"
import { GraphQLError } from "../shared/GraphQLError"
import InsuranceHeader from "../shared/Headers/InsuranceHeader"
import Loading from "../shared/Loading"
import CanceledInsuranceHeader from "./CancelInsuranceTable/CanceledInsuranceHeader"
import CanceledInsuranceList from "./CancelInsuranceTable/CanceledInsuranceList"
import { insuranceGQL } from "./InsuranceGQL"

const Insurance = () => {
    const { id } = useParams()
    assert(id)
    const { t } = useTranslation()

    const { data, loading, error } = useQuery<InsuranceQuery, InsuranceQueryVariables>(insuranceGQL, {
        fetchPolicy: "network-only",
        variables: { id: id },
    })

    if (!data || error) {
        return <GraphQLError error={error} />
    }
    if (loading) {
        return <Loading />
    }
    const insurance = data.insurance
    if (!insurance) {
        return <p>{t("insuranceNotFound")}</p>
    }
    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <BreadCrumb name={t("member")} to={`/member/${insurance.member.id}`} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <InsuranceHeader firstName={insurance.member.firstName} lastName={insurance.member.lastName} />
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>{t("from")}:{insurance.parameters.homeCountry.name}</div>
                </ListItem>

                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>{t("startDate")}:{insurance.parameters.startDate}</div>
                </ListItem>

                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    <div>{t("endDate")}:{insurance.parameters.endDate}</div>
                </ListItem>
            </List>
            <div className="button-center">
                <Link className="no-style-tag" to={`/withdraw/${insurance.id}`}>
                    <Button label={t("withdrawInsurance")} outlined />
                </Link>
                <Link className="no-style-tag" to={`/cancel-insurance/${id}`}>
                    <Button label={t("cancelInsurance")} outlined />
                </Link>
            </div>
            <DataTable className="table-width">
                <DataTableContent>
                    <CanceledInsuranceHeader />
                    <CanceledInsuranceList canceledInsurances={insurance.cancellations} />
                </DataTableContent>
            </DataTable>
        </div>
    )
}

export default Insurance
