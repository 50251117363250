import i18n from "i18next"
import { initReactI18next } from "react-i18next"
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
export const defaultNS = "translation"
export const resources = {
    en: {
        translation: {
            login: "Login",
            emailAddress: "Email Address",
            password: "Password",
            logOff: "Log Off",
            dashboard: "Dashboard",
            users: "Users",
            firstName: "First Name",
            lastName: "Last Name",
            actions: "Actions",
            createUser: "Create User",
            phoneNumberAmount: "Select amount of phone numbers",
            phoneNumber: "Phone Number",
            confirm: "Confirm",
            editUser: "Edit User",
            dj: "Dj",
            djCategories: "Dj Categories",
            djs: "DJs",
            label: "Label",
            priceExcludingVatInHighSeason: "Price exluding vat in high season",
            priceExcludingVatInLowSeason: "Price exluding vat in low season",
            pricePerOvertimeUnit: "Price per overtime unit",
            compensationPerHourOfCommute: "Compensation per hour of commute",
            compensationPerOvertimeUnit: "Compensation per overtime unit",
            compensationInHighSeason: "Compensation in high season",
            compensationInLowSeason: "Compensation in low season",
            createDjCategory: "Create Dj Category",
            editDjCategory: "Edit Dj Category",
            close: "Close",
            delete: "Delete",
            actionDialog: "Action Dialog",
            alias: "Alias",
            businessPhoneNumber: "Business Phone Number",
            homePhoneNumber: "Home Phone Number",
            mobilePhoneNumber: "Mobile Phone Number",
            remarks: "Remarks",
            djCategory: "DJ Category",
            createDj: "Create DJ",
            editDj: "Edit DJ",
            magazines: "Magazines",
            createMagazine: "Create Magazine",
            editMagazine: "Edit Magazine",
            magazine: "Magazine",
            name: "Name",
            weddingFairs: "Wedding Fairs",
            createWeddingFairs: "Create Wedding Fairs",
            editWeddingFairs: "Edit Wedding Fairs",
            referrals: "Referrals",
            musicPreferences: "Music Preferences",
            createMusicPreferences: "Create Music Preference",
            editMusicPreferences: "Edit Music Preferences",
            events: "Events",
            eventConsultations: "Event Consultations",
            createEventConsultations: "Create Event Consultations",
            editEventConsultations: "Edit Event Consultations",
            eventOccasion: "Event Occasion",
            createEventOccasion: "Create Event Occasion",
            editEventOccasion: "Edit Event Occasion",
            isMiscellaneous: "Miscellaneous",
            processTags: "Process Tags",
            createProcessTags: "Create Process Tags",
            editProcessTags: "Edit Process Tags",
            process: "Process",
            equipments: "Equipments",
            createEquipments: "Create Equipment",
            editEquipments: "Edit Equipment",
            type: "Type",
            priceExcludingVat: "Price Exluding Vat",
            processEmailTemplates: "Process Email Templates",
            createProcessEmailTemplates: "Create Process Email Templates",
            editProcessEmailTemplates: "Edit Process Email Templates",
            message: "Message",
            subject: "Subject",
            description: "Description",
            priority: "Priority",
            limitPerProcess: "Limit Per Process",
            isPrimary: "Primary",
            processParticipationTags: "Process Participation Tags",
            createProcessParticipationTags: "Create Process Participation Tags",
            editProcessParticipationTags: "Edit Process Participation Tags",
            eventSeason: "Event Season",
            low: "Low",
            high: "High",
            selectDate: "Select Date",
            noEventSeasonValue: "There is no event season value for this date",
            addEventSeason: "Add an event season to date",
            changeEventSeason: "Change event season for this date",
            selectEventSeason: "Select Event Season",
            noChanges: "No changes",
            documentTemplateBlocks: "Document Template Blocks",
            code: "Code",
            documentTypes: "Document Types",
            salutation: "Salutation",
            target: "Target",
            createDocumentTemplateBlocks: "Create Document Template Blocks",
            editDocumentTemplateBlocks: "Edit Document Template Blocks",
            selectVariable: "Please select the variable",
            variable: "Variable",
            editSalutations: "Edit Salutations",
            createSalutations: "Create Salutations",
            salutations: "Salutations ",
            eventCount: "Count",
            location: "Location",
            eventsLocation: "Events by location",
            from: "From",
            to: "To",
            recommendation: "Recommendation",
            recommendationsLocation: "Recommendations by location",
            bookingsChart: "Bookings per week",
            requestsChart: "Requests per week",
            editDocument: "Edit Document",
            archive: "Archive",
            recipient: "Recipient",
            contract: "New Contract",
            proposal: "New Proposal",
            farewell: "Farewell",
            greeting: "Greeting",
            postscript: "Postscript",
            preamble: "Preamble",
            bookingConfirmation: "Booking Confirmation",
            offer: "Offer",
            regarding: "Regarding",
            showArchived: "Show Archived",
            dateOfBirth: "Date of Birth",
            members: "Members",
            drWalterSyncQueue: "Dr walter sync",
            insuranceTable: "Insurance",
            addPerson: "Add Person",
            addInsurance: "Add Insurance",
            sync: "Sync",
            withDrWalter: "with DR-WALTER",
            updatePerson: "Update Person",
            updateInsurance: "Update Insurance",
            withdrawInsurance: "Withdraw insurance",
            provideInsuranceCertificate: "Provide insurance certificate",
            signIn: "Sign in",
            withCorrectAccount: "with the correct account.",
            goTo: "Go to",
            click: "Click ",
            enterInsurance: "Enter insurance",
            insuredPerson: "Insured person",
            leaveUnchanged: "leave unchanged",
            startDate: "Start date",
            endDate: "End date",
            persTravelingUSACan: "Person is traveling to USA/Canada",
            hCountry: "Home country",
            travelDest: "Travel destination",
            travelDestinationWorldWide: "worldwide",
            travelDestinationWorldWideExcludingCanadaAndUSA: "worldwide excl. Canada & USA",
            insurance: "Insurance",
            costCenter: "Cost center",
            saveInsur: "Save Insurance",
            enterUrlToInsur: "Enter URL to insurance",
            empWithAdminRight: "Employees with administrative rights",
            enrollParticipants: "Enroll participants",
            pleaseSelect: "Please select",
            noSpec: "don't specify",
            title: "Title",
            leaveBlank: "leave blank",
            userGroupAuth: "User group and authorisation",
            person: "Person",
            isNotAuthJourney: "is not authorized to enter journeys for himself/herself",
            enterUrlToPers: "Enter URL to person",
            downloadThe: "Download the",
            insurCert: "insurance certificate",
            fileWithoutRename: "file without renaming it",
            selectDownFile: "Select the downloaded file here",
            editThe: "Edit the",
            leaveRestAsIs: "Leave the rest as is",
            save: "Save",
            new: "New",
            repeat: "Repeat",
            go: "Go",
            cancelInsur: "Cancel the insurance",
            ifStillPoss: "if still possible",
            notifyPatricia: "Notify Patricia Cürten",
            fromDrWaltAbWithdraw: "from DR-WALTER about the withdrawal",
            cancelInsuranceOf: "Cancel insurance of",
            changeEmailAddressOf: "Change email address of",
            revokeInsuranceFor: "Revoke insurance cancellation for",
            homeCountryChangeReq: "Home country change request by",
            reason: "Reason",
            whenInDoubtWriteAt: "When in doubt then write that member at ",
            homeCountryChange: "Home Country Change",
            newInsurance: "New Insurance",
            regionChange: "Region Change",
            changeRequest: "Change Request",
            PrepareUploadOutputInvalidContentType: "Your documents content was invalid, please try again!",
            PrepareUploadOutputInvalidName: "Your documents name was invalid, please try again!",
            PrepareUploadOutputInvalidSize: "Your documents size was invalid, please try again!",
            ChangeMemberEmailAddressOutputEmailAddressInUse: "The email address that you submitted is currently in use by another user, please try again!",
            ChangeMemberEmailAddressOutputInvalidEmailAddress:
                "The email address that you submitted is invalid, if a mistake is made please contact us by email.",
            CancelInsuranceOutputAlreadyCancelled: "Insurance is already cancelled!",
            CancelInsuranceOutputEffectiveDateNotAllowed: "The selected effective date is not allowed!",
            CancelInsuranceOutputNotificationsNotAllowed: "Notifications are not allowed!",
            CancelInsuranceOutputReasonDetailsNotAllowed: "Reason is not allowed!",
            SignInOutputInvalidCredentials: "Invalid Credentials, please try again!",
            AcceptInsuranceQuoteOutputNotPossible: "Impossible to accept this insurance quote, please contact us by email.",
            AcceptInsuranceQuoteOutputQuoteNotFound: "This insurance quote is not found.",
            RevokeInsuranceCancellationOutputAlreadyRevoked: "This insurance cancellation is already revoked",
            RevokeInsuranceCancellationOutputCancellationNotFound: "This insurance cancellation can not be found",
            RevokeInsuranceCancellationOutputTooLate: "It is too late to revoke this insurance cancellation!",
            RevokeInsuranceCancellationOutputInsuranceWithdrawn: "This insurance cancellation is already withdrawn",
            noData: "There is no data to display",
            internalError: "Internal error",
            unknownGQLError: "Unknown GraphQL Error",
            goBackTo: "Do you want to go back to",
            administrator: "Administrator",
            notifications: "Notifications",
            effectiveDate: "Effective Date",
            insuranceOf: "Insurance of",
            true: "True",
            false: "False",
            noFirstName: "No First Name",
            noLastName: "No Last Name",
            noAdmin: "No Administrator",
            memberHeaderText: "This is member",
            quoteNotFound: "Quote not found.",
            AcceptInsuranceQuoteOutputQuoteNotFoundCountryChange: "Only home country change quotes are supported.",
            memberNotFound: "Member not found",
            registrationTimestampMember: "Registration Date",
            emailIsNotVerified: "Email is not verified",
            userHomeCountry: "Home Country",
            phoneNumberIsNotVerified: "Phone Number is not verified",
            WithdrawInsuranceAgreementOutputAlreadyWithdrawn: "Already Withdrawn",
            WithdrawInsuranceAgreementOutputAgreementNotFound: "Insurance not found",
            WithdrawInsuranceAgreementOutputNotificationsNotAllowed: "Notifications not allowed",
            WithdrawInsuranceAgreementOutputNotPossible: "Not possible",
            WithdrawInsuranceAgreementOutputReasonDetailsNotAllowed: "Reason Details not allowed",
            isInSync: "is in sync with DR-WALTER's system.",
            insuranceNotFound: "Insurance not found",
            insuranceCancellationNotFound:"Insurance Cancellation not found",
            drWalterSyncTaskNotFound:"Dr Walter Sync Task not found",
            withdrawInsuranceOf:"Withdraw Insurance of",
            loading:"Loading",
            status:"Status",
            cancelInsurance:"Cancel Insurance",
            acceptChange:"Accept Change",
            member:"Member",
            currentPage:"Current Page",
            BreachOfContract:"Breach of contract",
            Deceased:"Deceased",
            InsuranceFraud:"Insurance fraud",
            LocalInsuranceAtNewResidence:"I get insurance at my new residence",
            NoHealthInsurance:"I do not want any health insurance",
            NotSpecified:"Not specified",
            Other:"I have other reasons",
            OtherInternationalInsurance:"I get another international health insurance",
            PaymentDefault:"Payment default",
            PaymentFraud:"Payment fraud",
            Repatriation:"Repatriation",
            ReturnHome:"ReturnHome"
        },
    },
} as const

i18n.use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: "en", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
        // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
        // if you're using a language detector, do not define the lng option
        ns: ["translation"],
        interpolation: {
            escapeValue: false, // not needed for react!!
            formatSeparator: ",",
            format(value, format) {
                if (format === "uppercase") return value.toUpperCase()
                return value
            },
        },
    })

export default i18n
