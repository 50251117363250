import { useQuery } from "@apollo/client"
import { t } from "i18next"
import { useParams } from "react-router-dom"
import {
    DrwalterInsurancePlan,
    DrwalterSyncTaskQuery,
    DrwalterSyncTaskQueryVariables,
    InsuranceRegion,
    MemberQuery,
    MemberQueryVariables,
} from "../../generated"
import { memberGQL } from "../Member/MemberGQL"
import { assert } from "../shared/Error"
import { GraphQLError } from "../shared/GraphQLError"
import Loading from "../shared/Loading"
import "./syncMember.scss"
import AddInsurance from "./SyncMemberCases/AddInsurance"
import AddPerson from "./SyncMemberCases/AddPerson"
import { MemberSynced } from "./SyncMemberCases/MemberSynced"
import ProvideInsuranceCertificate from "./SyncMemberCases/ProvideInsuranceCertificate "
import UpdateInsurance from "./SyncMemberCases/UpdateInsurance "
import UpdatePerson from "./SyncMemberCases/UpdatePerson"
import WithdrawInsurance from "./SyncMemberCases/WithdrawInsurance"
import { drwalterSyncTaskGQL } from "./SyncMemberGQL"

const SyncMember = () => {
    const { id } = useParams()
    assert(id)
    const { data, loading, error } = useQuery<DrwalterSyncTaskQuery, DrwalterSyncTaskQueryVariables>(drwalterSyncTaskGQL, {
        fetchPolicy: "network-only",
        variables: { memberId: id },
    })
    const { data: memberData } = useQuery<MemberQuery, MemberQueryVariables>(memberGQL, {
        variables: { id },
    })
    if (loading) {
        return <Loading />
    }
    if (!data || error) {
        return <GraphQLError error={error} />
    }
    if (data.drwalterSyncTask === null) {
        return <MemberSynced name={`${memberData?.member?.firstName} ${memberData?.member?.lastName}`} />
    }
    if (data.drwalterSyncTask === undefined) {
        return <p>{t("drWalterSyncTaskNotFound")}</p>
    }
    const typeName = data.drwalterSyncTask.__typename
    switch (typeName) {
        case "DrwalterSyncTaskAddInsurance":
            return (
                <AddInsurance
                    id={data.drwalterSyncTask ? data.drwalterSyncTask.genkiInsurance.id : ""}
                    personId={data.drwalterSyncTask.person?.id}
                    firstName={data.drwalterSyncTask.person?.firstName}
                    lastName={data.drwalterSyncTask.person?.lastName}
                    period={data.drwalterSyncTask.period}
                    includeCanadaAndUSA={data?.drwalterSyncTask.includeCanadaAndUSA ?? false}
                    homeCountry={data.drwalterSyncTask ? data.drwalterSyncTask.homeCountry.name : ""}
                    homeCountryCode={data.drwalterSyncTask ? data.drwalterSyncTask.homeCountry.code : ""}
                    plan={data.drwalterSyncTask ? data.drwalterSyncTask.plan : DrwalterInsurancePlan.Age_18To_29Deductible_0}
                    genkiEffectiveDate={data.drwalterSyncTask ? data.drwalterSyncTask.genkiEffectiveDate : ""}
                />
            )
        case "DrwalterSyncTaskAddPerson":
            return (
                <AddPerson
                    firstName={data.drwalterSyncTask.firstName}
                    lastName={data.drwalterSyncTask.lastName}
                    emailAddress={data.drwalterSyncTask.emailAddress}
                    dateOfBirth={data.drwalterSyncTask.dateOfBirth}
                    id={data.drwalterSyncTask ? data.drwalterSyncTask.genkiMember.id : ""}
                />
            )
        case "DrwalterSyncTaskProvideInsuranceCertificate":
            return (
                <ProvideInsuranceCertificate
                    insuranceId={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.id : ""}
                    agreementId={data.drwalterSyncTask ? data.drwalterSyncTask.agreement.id : ""}
                    firstName={data.drwalterSyncTask.person?.firstName}
                    lastName={data.drwalterSyncTask.person?.lastName}
                />
            )
        case "DrwalterSyncTaskUpdateInsurance":
            return (
                <UpdateInsurance
                    id={data.drwalterSyncTask ? data.drwalterSyncTask.current.id : ""}
                    genkiId={data.drwalterSyncTask ? data.drwalterSyncTask.current.genkiInsurance.id : ""}
                    firstName={data.drwalterSyncTask.person.firstName}
                    lastName={data.drwalterSyncTask.person.lastName}
                    period={data.drwalterSyncTask.period}
                    includeCanadaAndUSA={data.drwalterSyncTask?.includeCanadaAndUSA ?? false}
                    countryName={data.drwalterSyncTask ? data.drwalterSyncTask.homeCountry.name : ""}
                    homeCountryCode={data.drwalterSyncTask ? data.drwalterSyncTask.homeCountry.code : ""}
                    plan={data.drwalterSyncTask ? data.drwalterSyncTask.plan : DrwalterInsurancePlan.Age_18To_29Deductible_0}
                    genkiEffectiveDate={data.drwalterSyncTask ? data.drwalterSyncTask.current.genkiEffectiveDate : ""}
                    memberCountryName={data.drwalterSyncTask ? data.drwalterSyncTask.current.homeCountry.name : ""}
                    memberPeriod={data.drwalterSyncTask.current.period}
                />
            )
        case "DrwalterSyncTaskUpdatePerson":
            return (
                <UpdatePerson
                    id={data.drwalterSyncTask ? data.drwalterSyncTask.current.id : ""}
                    genkiId={data.drwalterSyncTask ? data.drwalterSyncTask.current.genkiMember.id : ""}
                    firstName={data.drwalterSyncTask.firstName}
                    lastName={data.drwalterSyncTask.lastName}
                    emailAddress={data.drwalterSyncTask.emailAddress}
                    dateOfBirth={data.drwalterSyncTask.dateOfBirth}
                    memberEmailAddress={data.drwalterSyncTask.current.emailAddress}
                />
            )
        case "DrwalterSyncTaskWithdrawInsurance":
            return (
                <WithdrawInsurance
                    id={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.id : ""}
                    genkiId={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.genkiInsurance.id : ""}
                    firstName={data.drwalterSyncTask.person.firstName}
                    lastName={data.drwalterSyncTask.person.lastName}
                    emailAddress={data.drwalterSyncTask.person.emailAddress}
                    period={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.period : ""}
                    region={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.region : InsuranceRegion.WorldwideExcludingCanadaAndUsa}
                    homeCountryCode={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.homeCountry.code : ""}
                    plan={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.plan : DrwalterInsurancePlan.Age_18To_29Deductible_0}
                    genkiEffectiveDate={data.drwalterSyncTask ? data.drwalterSyncTask.insurance.genkiEffectiveDate : ""}
                />
            )
        default:
            return <></>
    }
}

export default SyncMember
