import { DataTableBody, DataTableCell, DataTableRow } from "@rmwc/data-table"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { sort } from "../../shared/Sort"
import { drWalterMembersType, membersType, sortOrderType } from "../types"

const MembersList = ({ members, sortOrder, sync }: { members: membersType[] | drWalterMembersType[]; sortOrder: sortOrderType; sync?: boolean }) => {
    const memoizedMembers = useMemo(() => sort(members, sortOrder), [members, sortOrder])
    const { t } = useTranslation()
    let navigate = useNavigate()
    if (members.length === 0) {
        return <h2>{t("noData")}</h2>
    }
    if (sync) {
        return (
            <DataTableBody>
                {memoizedMembers.map((e: membersType | drWalterMembersType) => {
                    return (
                        <DataTableRow key={e.id}>
                            <DataTableCell alignEnd onClick={() => navigate(`sync-member/${e.id}`, { replace: false })}>
                                {`${e.firstName} ${e.lastName}`}
                            </DataTableCell>
                            <DataTableCell alignEnd onClick={() => navigate(`sync-member/${e.id}`, { replace: false })}>
                                {e.dateOfBirth}
                            </DataTableCell>
                            <DataTableCell alignEnd onClick={() => navigate(`sync-member/${e.id}`, { replace: false })}>
                                {e.emailAddress}
                            </DataTableCell>

                            <DataTableCell alignEnd onClick={() => navigate(`sync-member/${e.id}`, { replace: false })}>
                                <IconButton
                                    onClick={a => {
                                        a.stopPropagation()
                                        navigate(`sync-member/${e.id}`, { replace: false })
                                    }}
                                    icon="sync_alt"
                                    label="Sync"
                                />
                            </DataTableCell>
                        </DataTableRow>
                    )
                })}
            </DataTableBody>
        )
    }
    return (
        <DataTableBody>
            {memoizedMembers.map((e: membersType | drWalterMembersType) => {
                return (
                    <DataTableRow key={e.id}>
                        <DataTableCell alignEnd onClick={() => navigate(`member/${e.id}`, { replace: false })}>
                            {`${e.firstName} ${e.lastName}`}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`member/${e.id}`, { replace: false })}>
                            {e.dateOfBirth}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`member/${e.id}`, { replace: false })}>
                            {e.emailAddress}
                        </DataTableCell>

                        <DataTableCell alignEnd onClick={() => navigate(`member/${e.id}`, { replace: false })}>
                            <IconButton
                                onClick={a => {
                                    a.stopPropagation()
                                    navigate(`member/${e.id}`, { replace: false })
                                }}
                                icon="person"
                                label="Member"
                            />
                            <IconButton
                                onClick={a => {
                                    a.stopPropagation()
                                    navigate(`change-email-address/${e.id}`, { replace: false })
                                }}
                                icon="alternate_email"
                                label="Change Email Address"
                            />
                        </DataTableCell>
                    </DataTableRow>
                )
            })}
        </DataTableBody>
    )
}

export default MembersList
