import { DataTableHead, DataTableHeadCell, DataTableRow } from "@rmwc/data-table"
import { useTranslation } from "react-i18next"

const CanceledInsuranceHeader = () => {
    const { t } = useTranslation()

    return (
        <DataTableHead>
            <DataTableRow>
                <DataTableHeadCell alignEnd>{t("administrator")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("effectiveDate")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("reason")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("notifications")}</DataTableHeadCell>
            </DataTableRow>
        </DataTableHead>
    )
}

export default CanceledInsuranceHeader
