import { Typography } from "@rmwc/typography"
import "@rmwc/typography/styles"

const FormHeader = ({ headerText }: { headerText: string }) => {
    return (
        <>
            <Typography use="headline2">{headerText}</Typography>
        </>
    )
}
export default FormHeader
