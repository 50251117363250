import { DataTableHead, DataTableHeadCell, DataTableRow } from "@rmwc/data-table"
import { useTranslation } from "react-i18next"
const InsuranceHeader = () => {
    const { t } = useTranslation()

    return (
        <DataTableHead>
            <DataTableRow>
                <DataTableHeadCell alignEnd>{t("hCountry")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("startDate")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("endDate")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("status")}</DataTableHeadCell>
            </DataTableRow>
        </DataTableHead>
    )
}

export default InsuranceHeader
