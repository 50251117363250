import Routing from "./Module/Routing"
import "@rmwc/typography/styles"
import "./App.scss"
function App() {
    return (
        <div className="App">
            <Routing />
        </div>
    )
}

export default App
