import { gql } from "@apollo/client"

export const insuranceQuoteGQL = gql`
    query insuranceQuote($id: ID!) {
        insuranceQuote(id: $id) {
            id
            user {
                firstName
                lastName
                emailAddress
            }
            insurance {
                parameters {
                    homeCountry {
                        code
                        name
                    }
                }
            }
            subject {
                ... on InsuranceSubjectHomeCountryChange {
                    homeCountry {
                        code
                        name
                    }
                    reason
                }
            }
        }
    }
`
export const acceptInsuranceQuoteGQL = gql`
    mutation acceptInsuranceQuote($input: AcceptInsuranceQuoteInput!) {
        acceptInsuranceQuote(input: $input) {
            ... on AcceptInsuranceQuoteOutputAccepted {
                __typename
            }
        }
    }
`
