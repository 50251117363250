import { useMutation } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { List, ListItem, ListItemMeta } from "@rmwc/list"
import "@rmwc/list/styles"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { DrwalterInsurancePlan, InsuranceRegion, RegisterDrwalterInsuranceMutation, RegisterDrwalterInsuranceMutationVariables } from "../../../generated"
import { Copy } from "../../shared/CopyToClipBoard"
import { DrWalterDateFormat } from "../../shared/FormatDate"
import { GraphQLError } from "../../shared/GraphQLError"
import SyncMemberCasesHeader from "../../shared/Headers/SyncMemberCasesHeader"
import { CopyToolTip } from "../../shared/ToolTips"
import { registerDrwalterInsuranceGQL } from "../SyncMemberGQL"

type periodType = { endInclusive: string; start: string }

const UpdateInsurance = ({
    id,
    genkiId,
    firstName,
    lastName,
    period,
    includeCanadaAndUSA,
    countryName,
    homeCountryCode,
    plan,
    genkiEffectiveDate,
    memberCountryName,
    memberPeriod,
}: {
    id: string
    genkiId: string
    firstName: string
    lastName: string
    period: periodType
    includeCanadaAndUSA: boolean
    countryName: string
    homeCountryCode: string
    plan: DrwalterInsurancePlan
    genkiEffectiveDate: string
    memberCountryName: string
    memberPeriod: periodType
}) => {
    const [registerDrwalterInsurance, { data, error }] = useMutation<RegisterDrwalterInsuranceMutation, RegisterDrwalterInsuranceMutationVariables>(
        registerDrwalterInsuranceGQL
    )

    let navigate = useNavigate()
    const { t } = useTranslation()

    async function submit() {
        const response = await registerDrwalterInsurance({
            variables: {
                input: {
                    genkiEffectiveDate: genkiEffectiveDate,
                    genkiId: genkiId,
                    homeCountryCode: homeCountryCode,
                    id: id,
                    isWithdrawn: false,
                    period: period,
                    plan: plan,
                    region: includeCanadaAndUSA ? InsuranceRegion.Worldwide : InsuranceRegion.WorldwideExcludingCanadaAndUsa,
                },
            },
        })
        if (response.data?.registerDrwalterInsurance.__typename === "RegisterDrwalterInsuranceOutputRegistered") {
            navigate(0)
        }
    }

    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <SyncMemberCasesHeader firstName={firstName} lastName={lastName} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography use="subtitle1">{t("updateInsurance")}</Typography>
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>
                        <a href="https://www.dr-walter-gmbh.com/miaplus/?l=en" target="_blank">
                            {t("signIn")}
                        </a>{" "}
                        {t("withCorrectAccount")}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>
                        {t("editThe")}{" "}
                        <a
                            href={`https://www.dr-walter-gmbh.com/miaplus/versicherungen/vers_edit.html?versicherung_id=${id.replace(
                                "drwalter insurance:",
                                ""
                            )}&l=en`}
                            target="_blank"
                        >
                            {t("insurance")}
                        </a>
                        .
                    </div>
                </ListItem>
                {countryName !== memberCountryName ? (
                    <CopyToolTip name={countryName}>
                        <ListItem
                            onClick={() => {
                                Copy(countryName)
                            }}
                        >
                            <div className="mdc-list-item__graphic">3</div>
                            <div><b>{t("hCountry")}: </b> {countryName}</div>
                            <ListItemMeta>
                                <IconButton
                                    className="padding-is-0"
                                    icon="content_copy"
                                    label="Copy Country Name"
                                    onClick={() => {
                                        Copy(countryName)
                                    }}
                                />
                            </ListItemMeta>
                        </ListItem>
                    </CopyToolTip>
                ) : (
                    <></>
                )}
                {period.endInclusive !== memberPeriod.endInclusive ? (
                    <CopyToolTip name={DrWalterDateFormat(new Date(period.endInclusive))}>
                        <ListItem
                            onClick={() => {
                                Copy(DrWalterDateFormat(new Date(period.endInclusive)))
                            }}
                        >
                            <div className="mdc-list-item__graphic">4</div>
                            <div><b>{t("endDate")}:</b> {DrWalterDateFormat(new Date(period.endInclusive))}</div>
                            <ListItemMeta>
                                <IconButton
                                    className="padding-is-0"
                                    icon="content_copy"
                                    label="Copy End date"
                                    onClick={() => {
                                        Copy(DrWalterDateFormat(new Date(period.endInclusive)))
                                    }}
                                />
                            </ListItemMeta>
                        </ListItem>
                    </CopyToolTip>
                ) : (
                    <></>
                )}
                <ListItem>
                    <div className="mdc-list-item__graphic">5</div>
                    <div>{t("leaveRestAsIs")}.</div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">6</div>
                    <div>{t("click")} <b>{t("save")}</b></div>
                </ListItem>
            </List>

            <div className="button-center">
                <Button label={t("confirm")} outlined onClick={submit} />
            </div>
            <GraphQLError error={error} />
        </div>
    )
}

export default UpdateInsurance
