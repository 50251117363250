export function assert(condition: any): asserts condition {
    if (!condition) {
        throw new Error("Assertion failed.")
    }
}


export function error(message?: string): never {
    throw new Error(message === undefined ? 'This should never happen…' : message)
}
