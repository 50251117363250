export function AddPersonRegex(url:string):string{
    const id =url.match("https://www\\.dr-walter-gmbh\\.com/miaplus/(?:mitarbeiter|personen)/(?:mit|pers)_detail/(\\d+)\\.html")
    if (id===null){
        return ''
    }else{
        return `${new String(id[1])}`
    }
}
export function AddInsuranceRegex(url:string):string{
    const id =url.match("https://www\\.dr-walter-gmbh\\.com/miaplus/versicherungen/vers_detail/(\\d+)\\.html")
    if (id===null){
        return ''
    }else{
        return `${new String(id[1])}`
    }
}

export function ProvideInsuranceRegex(url:string):string{
    const id =url.match("drwalter insurance:(\\d+)")
    if (id===null){
        return ''
    }else{
        return `${new String(id[1])}`
    }
}