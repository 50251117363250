import { gql } from "@apollo/client"

export const withdrawInsuranceAgreementGQL = gql`
    mutation withdrawInsuranceAgreement($input: WithdrawInsuranceAgreementInput!) {
        withdrawInsuranceAgreement(input: $input) {
            ... on WithdrawInsuranceAgreementOutputWithdrawn {
                __typename
            }
        }
    }
`
