import { useMutation, useQuery } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import "@rmwc/typography/styles"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import { ChangeMemberEmailAddressMutation, ChangeMemberEmailAddressMutationVariables, MemberQuery, MemberQueryVariables } from "../../generated"
import { memberGQL } from "../Member/MemberGQL"
import { GraphQLError } from "../shared/GraphQLError"
import Loading from "../shared/Loading"
import ChangeEmailAdressHeader from "../shared/Headers/ChangeEmailAdressHeader"
import { TypeErrorForms } from "../shared/TypeError"
import { changeMemberEmailAddressGQL } from "./ChangeEmailAdressGQL"
import { assert } from "../shared/Error"

const validationSchema = yup.object({
    emailAddress: yup.string().required("Email Address is required").email("Needs to be a viable email address"),
})
const ChangeEmailAdress = () => {
    const { id } = useParams()
    assert(id)
    let navigate = useNavigate()

    const { t } = useTranslation()

    const { data, loading, error } = useQuery<MemberQuery, MemberQueryVariables>(memberGQL, { fetchPolicy: "network-only", variables: { id } })
    const [changeEmailAddress, { data: changeEmailAddressData, error: ChangeEmailAddressError }] = useMutation<
        ChangeMemberEmailAddressMutation,
        ChangeMemberEmailAddressMutationVariables
    >(changeMemberEmailAddressGQL)

    const formik = useFormik({
        initialValues: {
            emailAddress: "",
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            if (data && data.member) {
                const response = await changeEmailAddress({
                    variables: {
                        input: {
                            emailAddress: values.emailAddress,
                            memberId: data.member.id,
                        },
                    },
                })
                if (response.data?.changeMemberEmailAddress.__typename === "ChangeMemberEmailAddressOutputChanged") {
                    navigate("/?tab=drWalterSyncQueue")
                }
            }
        },
    })
    if (loading) {
        return <Loading />
    }
    if (error || !data || !data.member) {
        if (!data?.member) {
            return <TypeErrorForms error={true} errorMessage={t("memberNotFound")} />
        }
        return <GraphQLError error={error} />
    }
    const member = data.member

    return (
        <div className="list-wrapper" style={{ padding: "0px 10rem" }}>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <ChangeEmailAdressHeader firstName={member.firstName} lastName={member.lastName} emailAddress={member.emailAddress} />
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            type="email"
                            autoComplete="off"
                            outlined
                            className="text-field-width"
                            id="emailAddress"
                            name="emailAddress"
                            label="New Email Address"
                            value={formik.values.emailAddress}
                            onChange={formik.handleChange}
                            invalid={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                        />
                    </div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined type="submit" />
                </div>
                <TypeErrorForms
                    error={changeEmailAddressData?.changeMemberEmailAddress.__typename === "ChangeMemberEmailAddressOutputEmailAddressInUse"}
                    errorMessage={t("ChangeMemberEmailAddressOutputEmailAddressInUse")}
                />
                <TypeErrorForms
                    error={changeEmailAddressData?.changeMemberEmailAddress.__typename === "ChangeMemberEmailAddressOutputInvalidEmailAddress"}
                    errorMessage={t("ChangeMemberEmailAddressOutputInvalidEmailAddress")}
                />
                <GraphQLError error={ChangeEmailAddressError} />
            </form>
        </div>
    )
}

export default ChangeEmailAdress
