import { Typography } from "@rmwc/typography"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const BreadCrumb = ({ name, to }: { name: string; to: string }) => {
    const { t } = useTranslation()
    return (
        <>
            <Link to={to}>
                <Typography use="subtitle2">{`${name}`}</Typography>
            </Link>
            <Typography use="subtitle2">{` > ${t("currentPage")}`}</Typography>
        </>
    )
}
