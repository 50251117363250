import { useMutation } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { List, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from "@rmwc/list"
import "@rmwc/list/styles"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { RegisterDrwalterPersonMutation, RegisterDrwalterPersonMutationVariables } from "../../../generated"
import { Copy } from "../../shared/CopyToClipBoard"
import { DrWalterDateFormat } from "../../shared/FormatDate"
import { GraphQLError } from "../../shared/GraphQLError"
import SyncMemberCasesHeader from "../../shared/Headers/SyncMemberCasesHeader"
import { AddPersonRegex } from "../../shared/Regex"
import { CopyToolTip } from "../../shared/ToolTips"
import { registerDrwalterPersonGQL } from "../SyncMemberGQL"

const regExp = new RegExp("https://www\\.dr-walter-gmbh\\.com/miaplus/(?:mitarbeiter|personen)/(?:mit|pers)_detail/(\\d+)\\.html")

const validationSchema = yup.object({
    id: yup.string().required("Url is required").matches(regExp, "Url must be in the right format"),
})
const AddPerson = ({
    id,
    firstName,
    lastName,
    dateOfBirth,
    emailAddress,
}: {
    id: string
    firstName: string
    lastName: string
    dateOfBirth: string
    emailAddress: string
}) => {
    let navigate = useNavigate()
    const { t } = useTranslation()
    const [registerDrwalterPerson, { data, error }] = useMutation<RegisterDrwalterPersonMutation, RegisterDrwalterPersonMutationVariables>(
        registerDrwalterPersonGQL
    )
    const formik = useFormik({
        initialValues: {
            id: "",
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            const response = await registerDrwalterPerson({
                variables: {
                    input: {
                        firstName: firstName,
                        lastName: lastName,
                        emailAddress: emailAddress,
                        dateOfBirth: dateOfBirth,
                        genkiId: id,
                        id: AddPersonRegex(values.id),
                    },
                },
            })
            if (response.data?.registerDrwalterPerson.__typename === "RegisterDrwalterPersonOutputRegistered") {
                navigate(0)
            }
        },
    })
    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <SyncMemberCasesHeader firstName={firstName} lastName={lastName} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography use="subtitle1">{t("addPerson")}</Typography>
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>
                        <a href="https://www.dr-walter-gmbh.com/miaplus/?l=en" target="_blank">
                            {t("signIn")}
                        </a>{" "}
                        {t("withCorrectAccount")}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>
                        {t("goTo")} "{t("empWithAdminRight")}" &gt; "
                        <a href="https://www.dr-walter-gmbh.com/miaplus/mitarbeiter/mitarbeiter_new.html?l=en" target="_blank">
                            {t("enrollParticipants")}
                        </a>
                        ".
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    <div>
                        <b>{t("salutation")}:</b> "{t("pleaseSelect")}" (i.e. {t("noSpec")})
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">4</div>
                    <div>
                        <b>{t("title")}:</b> {t("leaveBlank")}
                    </div>
                </ListItem>
                <CopyToolTip name={firstName}>
                    <ListItem
                        onClick={() => {
                            Copy(firstName)
                        }}
                    >
                        <div className="mdc-list-item__graphic">5</div>
                        <div>
                            <b>{t("firstName")}:</b> {firstName}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy FirstName"
                                onClick={() => {
                                    Copy(firstName)
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <CopyToolTip name={lastName}>
                    <ListItem
                        onClick={() => {
                            Copy(lastName)
                        }}
                    >
                        <div className="mdc-list-item__graphic">6</div>
                        <div>
                            <b>{t("lastName")}:</b> {lastName}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy LastName"
                                onClick={() => {
                                    Copy(lastName)
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <CopyToolTip name={DrWalterDateFormat(new Date(dateOfBirth))}>
                    <ListItem
                        onClick={() => {
                            Copy(DrWalterDateFormat(new Date(dateOfBirth)))
                        }}
                    >
                        <div className="mdc-list-item__graphic">7</div>
                        <div>
                            <b>{t("dateOfBirth")}:</b> {DrWalterDateFormat(new Date(dateOfBirth))}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy DateOfBirth"
                                onClick={() => {
                                    Copy(DrWalterDateFormat(new Date(dateOfBirth)))
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <CopyToolTip name={emailAddress}>
                    <ListItem
                        onClick={() => {
                            Copy(emailAddress)
                        }}
                    >
                        <div className="mdc-list-item__graphic">8</div>
                        <div>
                            <b>{t("emailAddress")}:</b> {emailAddress}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy EmailAddress"
                                onClick={() => {
                                    Copy(emailAddress)
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <ListItem>
                    <div className="mdc-list-item__graphic">9</div>
                    <ListItemText>
                        <ListItemPrimaryText>
                            <b>{t("userGroupAuth")}:</b>
                        </ListItemPrimaryText>
                        <ListItemSecondaryText>
                            {t("person")} ({t("isNotAuthJourney")})
                        </ListItemSecondaryText>
                    </ListItemText>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">10</div>
                    <div>
                        {t("click")} <b>{t("enrollParticipants")}</b>
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">11</div>
                    {t("enterUrlToPers")}:
                </ListItem>
            </List>
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            type="url"
                            autoComplete="off"
                            outlined
                            className="text-field-width"
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            invalid={formik.touched.id && Boolean(formik.errors.id)}
                            helpText={{
                                persistent: true,
                                children: "Example: https://www.dr-walter-gmbh.com/miaplus/mitarbeiter/mit_detail/12345678.html",
                            }}
                        />
                    </div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined type="submit" />
                </div>
                <GraphQLError error={error} />
            </form>
        </div>
    )
}

export default AddPerson
