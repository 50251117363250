import { TopAppBar, TopAppBarActionItem, TopAppBarFixedAdjust, TopAppBarRow, TopAppBarSection, TopAppBarTitle } from "@rmwc/top-app-bar"
import "@rmwc/top-app-bar/styles"
import { useState } from "react"
import { Link } from "react-router-dom"
import Logout from "./Logout/Logout"
const GenkiTopBar = () => {
    const [logout, setLogout] = useState(false)
    if (logout) {
        return <Logout />
    }
    return (
        <>
            <TopAppBar>
                <TopAppBarRow>
                    <TopAppBarSection alignStart>
                        <TopAppBarTitle style={{ cursor: "pointer" }}>
                            <Link className="no-style-tag" to={"/?tab=drWalterSyncQueue"}>
                                Genki Admin version {process.env.CF_PAGES_COMMIT_SHA}
                            </Link>
                        </TopAppBarTitle>
                    </TopAppBarSection>
                    <TopAppBarSection alignEnd>
                        <TopAppBarActionItem icon="logout" onClick={() => setLogout(true)} />
                    </TopAppBarSection>
                </TopAppBarRow>
            </TopAppBar>
            <TopAppBarFixedAdjust />
        </>
    )
}

export default GenkiTopBar
