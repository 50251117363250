import { ApolloError } from "@apollo/client"
import { Typography } from "@rmwc/typography"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

export const GraphQLError = ({ error }: { error?: ApolloError }) => {
    const { t } = useTranslation()
    if (error) {
        const graphQLErrors = error.graphQLErrors
        if (graphQLErrors.length > 0) {
            return (
                <>
                    {graphQLErrors.map(graphError => {
                        return (
                            <div className="form-item">
                                <div className="mdc-list-item__graphic"></div>
                                <Typography className="error" use="headline5">
                                    {graphError?.extensions?.code === "internal" ? t("internalError") : t("unknownGQLError")} ! {t("goBackTo")}{" "}
                                    <Link to="/?tab=drWalterSyncQueue">{t("dashboard")}</Link>?
                                </Typography>
                            </div>
                        )
                    })}
                </>
            )
        }
    }
    return <></>
}
export const GraphQLErrorLogin = ({ error }: { error?: ApolloError }) => {
    const { t } = useTranslation()
    if (error) {
        const graphQLErrors = error.graphQLErrors
        if (graphQLErrors.length > 0) {
            return (
                <>
                    {graphQLErrors.map(graphError => {
                        return (
                            <div className="login-items-wrapper">
                                <Typography className="error" use="headline5">
                                    {graphError?.extensions?.code === "internal" ? t("internalError") : t("unknownGQLError")} !
                                </Typography>
                            </div>
                        )
                    })}
                </>
            )
        }
    }
    return <></>
}
