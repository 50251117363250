import { gql } from "@apollo/client"

export const cancelInsuranceGQL = gql`
    mutation cancelInsurance($input: CancelInsuranceInput!) {
        cancelInsurance(input: $input) {
            ... on CancelInsuranceOutputCancelled {
                __typename
            }
        }
    }
`
export const insuranceGQL = gql`
    query insurance($id: ID!) {
        insurance(id: $id) {
            id
            member {
                firstName
                lastName
            }
            parameters {
                startDate
                endDate
            }
        }
    }
`
