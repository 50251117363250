import { gql } from "@apollo/client"

export const memberGQL = gql`
query member($id: ID!) {
    member(id: $id) {
        dateOfBirth
        emailAddress
        firstName
        id
        lastName
        phoneNumber
        emailAddressVerificationTimestamp
        phoneNumberVerificationTimestamp
        registrationTimestamp
        homeCountry{
            code
            name
        }
        insurances {
            id
            status
            parameters {
                startDate
                endDate
                homeCountry {
                    name
                }
            }
        }
    }
}
`
