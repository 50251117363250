import { useMutation } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import "@rmwc/elevation/styles"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import "@rmwc/typography/styles"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { Mutation, SignInInput } from "../../generated"
import FormHeader from "../shared/FormHeader"
import { GraphQLErrorLogin } from "../shared/GraphQLError"
import PasswordTextField from "../shared/PasswordTextField"
import { TypeErrorLogin } from "../shared/TypeError"
import "./login.scss"
import { signInGQL } from "./loginGQL"

const validationSchema = yup.object({
    emailAddress: yup.string().email("Enter a valid email").required("Email is required"),
    password: yup.string().required("Password is required"),
})

const Login = () => {
    const { t } = useTranslation()
    const [authenticate, { data, error }] = useMutation<Mutation, SignInInput>(signInGQL)
    let navigate = useNavigate()
    const formik = useFormik({
        initialValues: {
            emailAddress: "",
            password: "",
        },
        validationSchema: validationSchema,
        onSubmit: async (values: SignInInput) => {
            const authData = await authenticate({ variables: values })
            if (authData?.data?.signIn.__typename === "SignInOutputAuthenticated") {
                navigate("/?tab=drWalterSyncQueue")
            }
        },
    })

    return (
        <div>
            <form className="login-wrapper" onSubmit={formik.handleSubmit}>
                <div className="login-items-wrapper">
                    <FormHeader headerText={t("login")} />
                </div>
                <div className="login-items-wrapper text-field-width">
                    <TextField
                        outlined
                        className="text-field-width"
                        id="emailAddress"
                        name="emailAddress"
                        label={t("emailAddress")}
                        value={formik.values.emailAddress}
                        onChange={formik.handleChange}
                        invalid={formik.touched.emailAddress && Boolean(formik.errors.emailAddress)}
                        helpText={formik.touched.emailAddress && formik.errors.emailAddress}
                    />
                </div>
                <div className="login-items-wrapper text-field-width">
                    <PasswordTextField
                        outlined
                        className="text-field-width"
                        id="password"
                        name="password"
                        label={t("password")}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        invalid={formik.touched.password && Boolean(formik.errors.password)}
                        helpText={formik.touched.password && formik.errors.password}
                    />
                </div>
                <div className="login-items-wrapper">
                    <a href="#">
                        <Button label={t("confirm")} outlined type="submit" />
                    </a>
                </div>
                <TypeErrorLogin error={data?.signIn?.__typename === "SignInOutputInvalidCredentials"} errorMessage={t("SignInOutputInvalidCredentials")} />
                <GraphQLErrorLogin error={error} />
            </form>
        </div>
    )
}
export default Login
