import { useMutation } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { List, ListItem, ListItemMeta } from "@rmwc/list"
import "@rmwc/list/styles"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { RegisterDrwalterPersonMutation, RegisterDrwalterPersonMutationVariables } from "../../../generated"
import { Copy } from "../../shared/CopyToClipBoard"
import { GraphQLError } from "../../shared/GraphQLError"
import SyncMemberCasesHeader from "../../shared/Headers/SyncMemberCasesHeader"
import { CopyToolTip } from "../../shared/ToolTips"
import { registerDrwalterPersonGQL } from "../SyncMemberGQL"

const UpdatePerson = ({
    id,
    genkiId,
    firstName,
    lastName,
    emailAddress,
    dateOfBirth,
    memberEmailAddress,
}: {
    id: string
    genkiId: string
    firstName: string
    lastName: string
    emailAddress: string
    dateOfBirth: string
    memberEmailAddress: string
}) => {
    let navigate = useNavigate()
    const { t } = useTranslation()

    const [registerDrwalterPerson, { data, error }] = useMutation<RegisterDrwalterPersonMutation, RegisterDrwalterPersonMutationVariables>(
        registerDrwalterPersonGQL
    )
    async function submit() {
        const response = await registerDrwalterPerson({
            variables: {
                input: {
                    id: id,
                    genkiId: genkiId,
                    firstName: firstName,
                    lastName: lastName,
                    emailAddress: emailAddress,
                    dateOfBirth: dateOfBirth,
                },
            },
        })
        if (response.data?.registerDrwalterPerson.__typename === "RegisterDrwalterPersonOutputRegistered") {
            navigate(0)
        }
    }

    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <SyncMemberCasesHeader firstName={firstName} lastName={lastName} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography use="subtitle1">{t("updatePerson")}</Typography>
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>
                        <a href="https://www.dr-walter-gmbh.com/miaplus/?l=en" target="_blank">
                            {t("signIn")}
                        </a>{" "}
                        {t("withCorrectAccount")}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>
                        {t("goTo")} "{t("empWithAdminRight")}" &gt; "
                        <a href="https://www.dr-walter-gmbh.com/miaplus/mitarbeiter/mitarbeiter_new.html?l=en" target="_blank">
                            {t("enrollParticipants")}
                        </a>
                        ".
                    </div>
                </ListItem>
                {emailAddress !== memberEmailAddress ? (
                    <CopyToolTip name={emailAddress}>
                        <ListItem
                            onClick={() => {
                                Copy(emailAddress)
                            }}
                        >
                            <div className="mdc-list-item__graphic">3</div>
                            <div>
                                <b>
                                    {t("new")} {t("emailAddress")}:{" "}
                                </b>{" "}
                                {emailAddress}
                            </div>
                            <ListItemMeta>
                                <IconButton
                                    className="padding-is-0"
                                    icon="content_copy"
                                    label="Copy EmailAddress"
                                    onClick={() => {
                                        Copy(emailAddress)
                                    }}
                                />
                            </ListItemMeta>
                        </ListItem>
                    </CopyToolTip>
                ) : (
                    <></>
                )}
                <ListItem>
                    <div className="mdc-list-item__graphic">4</div>
                    <div>{t("repeat")}.</div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">5</div>
                    <div>
                        {t("click")} <b>{t("go")}</b>
                    </div>
                </ListItem>
            </List>

            <div className="button-center">
                <Button label={t("confirm")} outlined onClick={submit} />
            </div>
            <GraphQLError error={error} />
        </div>
    )
}

export default UpdatePerson
