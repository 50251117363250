import { DataTableBody, DataTableCell, DataTableRow } from "@rmwc/data-table"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { InsuranceCancellationReason } from "../../../generated"
import { TFunction } from "i18next"

type canceledInsurance = {
    __typename?: "InsuranceCancellation"
    id: string
    effectiveDate: any
    reason: InsuranceCancellationReason
    notificationsEnabled: boolean
    administrator?: { __typename?: "Administrator"; firstName: any; lastName: any } | null
}
function adminName(noFirstName: string, noLastName: string, noAdmin: string, firstName?: string, lastName?: string) {
    if (firstName || lastName) {
        if (!firstName) {
            return noFirstName + " " + lastName
        } else if (!lastName) {
            return firstName + " " + noLastName
        } else return firstName + " " + lastName
    } else {
        return noAdmin
    }
}
const ReturnReasonLabel = (reason: InsuranceCancellationReason, t: TFunction): string => {
    switch (reason) {
        case InsuranceCancellationReason.BreachOfContract:
            return t("BreachOfContract")
        case InsuranceCancellationReason.Deceased:
            return t("Deceased")
        case InsuranceCancellationReason.InsuranceFraud:
            return t("InsuranceFraud")
        case InsuranceCancellationReason.LocalInsuranceAtNewResidence:
            return t("LocalInsuranceAtNewResidence")
        case InsuranceCancellationReason.NoHealthInsurance:
            return t("NoHealthInsurance")
        case InsuranceCancellationReason.NotSpecified:
            return t("NotSpecified")
        case InsuranceCancellationReason.Other:
            return t("Other")
        case InsuranceCancellationReason.OtherInternationalInsurance:
            return t("OtherInternationalInsurance")
        case InsuranceCancellationReason.PaymentDefault:
            return t("PaymentDefault")
        case InsuranceCancellationReason.PaymentFraud:
            return t("PaymentFraud")
        case InsuranceCancellationReason.Repatriation:
            return t("Repatriation")
        case InsuranceCancellationReason.ReturnHome:
            return t("ReturnHome")
        default:
            return ""
    }
}
const CanceledInsuranceList = ({ canceledInsurances }: { canceledInsurances: canceledInsurance[] }) => {
    const { t } = useTranslation()
    let navigate = useNavigate()
    if (canceledInsurances.length === 0) {
        return <h2>{t("noData")}</h2>
    }
    return (
        <DataTableBody>
            {canceledInsurances.map((canceledInsuranceElement: canceledInsurance) => {
                return (
                    <DataTableRow key={canceledInsuranceElement.id}>
                        <DataTableCell alignEnd>
                            {adminName(
                                t("noFirstName"),
                                t("noLastName"),
                                t("noAdmin"),
                                canceledInsuranceElement?.administrator?.firstName,
                                canceledInsuranceElement?.administrator?.lastName
                            )}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/revoke-cancellation/${canceledInsuranceElement.id}`, { replace: false })}>
                            {" "}
                            {canceledInsuranceElement.effectiveDate}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/revoke-cancellation/${canceledInsuranceElement.id}`, { replace: false })}>
                            {ReturnReasonLabel(canceledInsuranceElement.reason, t)}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/revoke-cancellation/${canceledInsuranceElement.id}`, { replace: false })}>
                            {canceledInsuranceElement.notificationsEnabled ? t("true") : t("false")}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/revoke-cancellation/${canceledInsuranceElement.id}`, { replace: false })}>
                            <IconButton
                                onClick={e => {
                                    e.stopPropagation()
                                    navigate(`/revoke-cancellation/${canceledInsuranceElement.id}`, { replace: false })
                                }}
                                icon="restore_from_trash"
                                label="Revoke Cancellation"
                            />
                        </DataTableCell>
                    </DataTableRow>
                )
            })}
        </DataTableBody>
    )
}

export default CanceledInsuranceList
