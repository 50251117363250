import { useMutation, useQuery } from "@apollo/client"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useFormik } from "formik"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import {
    InsuranceQuery,
    CancelInsuranceMutation,
    InsuranceQueryVariables,
    CancelInsuranceMutationVariables,
    InsuranceCancellationReason,
} from "../../generated"
import { cancelInsuranceGQL, insuranceGQL } from "./CancelInsuranceGQL"
import { TextField } from "@rmwc/textfield"
import { Select } from "@rmwc/select"
import { Checkbox } from "@rmwc/checkbox"
import { Button } from "@rmwc/button"
import Loading from "../shared/Loading"
import CancelInsuranceHeader from "../shared/Headers/CancelInsuranceHeader"
import { TypeErrorForms } from "../shared/TypeError"
import { GraphQLError } from "../shared/GraphQLError"
import { assert } from "../shared/Error"
import "@rmwc/icon-button/styles"
import "@rmwc/list/styles"
import "@rmwc/textfield/styles"
import "@rmwc/button/styles"
import "@rmwc/checkbox/styles"
import "@rmwc/select/styles"
import "@rmwc/typography/styles"
import { BreadCrumb } from "../shared/BreadCrumbs"
import "./CancelInsurance.scss"

interface InsuranceCancellationReasonArrType {
    label: string
    value: InsuranceCancellationReason
}
const InsuranceCancellationReasonArr: InsuranceCancellationReasonArrType[] = [
    { label: "Breach of contract", value: InsuranceCancellationReason.BreachOfContract },
    { label: "Deceased", value: InsuranceCancellationReason.Deceased },
    { label: "Insurance fraud", value: InsuranceCancellationReason.InsuranceFraud },
    { label: "I get insurance at my new residence", value: InsuranceCancellationReason.LocalInsuranceAtNewResidence },
    { label: "I do not want any health insurance", value: InsuranceCancellationReason.NoHealthInsurance },
    { label: "Not specified", value: InsuranceCancellationReason.NotSpecified },
    { label: "I have other reasons", value: InsuranceCancellationReason.Other },
    { label: "I get another international health insurance", value: InsuranceCancellationReason.OtherInternationalInsurance },
    { label: "Payment default", value: InsuranceCancellationReason.PaymentDefault },
    { label: "Payment fraud", value: InsuranceCancellationReason.PaymentFraud },
    { label: "Repatriation", value: InsuranceCancellationReason.Repatriation },
    { label: "I return home", value: InsuranceCancellationReason.ReturnHome },
]

function detailsNotAllowedForReason(reason?: InsuranceCancellationReason): boolean {
    switch (reason) {
        case InsuranceCancellationReason.OtherInternationalInsurance:
            return false
        case InsuranceCancellationReason.Other:
            return false
        default:
            return true
    }
}

function notificationsNotAllowedForReason(reason?: InsuranceCancellationReason): boolean {
    switch (reason) {
        case InsuranceCancellationReason.Deceased:
            return true
        case InsuranceCancellationReason.Repatriation:
            return true
        case undefined:
            return true
        default:
            return false
    }
}

const validationSchema = yup.object({
    reason: yup.string().required("Reason is required"),
    details: yup.string(),
    note: yup.string().required("Note is required"),
    notifications: yup.boolean(),
    effectiveDate: yup.date().required("Effective Date is required"),
})

const CancelInsurance = () => {
    const { id } = useParams()
    assert(id)

    let navigate = useNavigate()
    const { t } = useTranslation()

    const { data, loading, error } = useQuery<InsuranceQuery, InsuranceQueryVariables>(insuranceGQL, { fetchPolicy: "network-only", variables: { id } })
    const [cancelInsurance, { data: cancelInsuranceData, error: cancelInsuranceError }] = useMutation<
        CancelInsuranceMutation,
        CancelInsuranceMutationVariables
    >(cancelInsuranceGQL)

    const formik = useFormik({
        initialValues: {
            reason: undefined,
            details: undefined,
            note: "",
            notifications: undefined,
            effectiveDate: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            if (!values.reason) {
                alert("Please select a reason")
            } else {
                const response = await cancelInsurance({
                    variables: {
                        input: {
                            effectiveDate: values.effectiveDate,
                            id,
                            internalNote: values.note,
                            notificationsEnabled: values.notifications ? values.notifications : false,
                            reason: values.reason,
                            reasonDetails: values.details,
                        },
                    },
                })
                if (response.data?.cancelInsurance.__typename === "CancelInsuranceOutputCancelled") {
                    navigate(`/insurance/${id}`)
                }
            }
        },
    })
    useEffect(() => {
        if (!notificationsNotAllowedForReason(formik.values.reason)) {
            if (!formik.values.notifications) {
                formik.setFieldValue("notifications", true)
            }
        }
        if (notificationsNotAllowedForReason(formik.values.reason)) {
            if (formik.values.notifications) {
                formik.setFieldValue("notifications", false)
            }
        }
        if (detailsNotAllowedForReason(formik.values.reason)) {
            if (formik.values.details) {
                formik.setFieldValue("details", "")
            }
        }
    }, [formik.values.reason])
    if (loading) {
        return <Loading />
    }
    if (error) {
        return <GraphQLError error={error} />
    }
    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <BreadCrumb name={t("insurance")} to={`/insurance/${id}`} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <CancelInsuranceHeader firstName={data?.insurance?.member.firstName} lastName={data?.insurance?.member.lastName} />
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <Select
                            outlined
                            style={{ width: "100%" }}
                            className="text-field-width"
                            id="reason"
                            name="reason"
                            label="Reason key"
                            value={formik.values.reason}
                            onChange={e => formik.setFieldValue("reason", e.currentTarget.value)}
                            invalid={formik.touched.reason && Boolean(formik.errors.reason)}
                            options={InsuranceCancellationReasonArr}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            outlined
                            className="text-field-width"
                            disabled={detailsNotAllowedForReason(formik.values.reason)}
                            id="details"
                            name="details"
                            label="Reason details"
                            value={formik.values.details}
                            onChange={formik.handleChange}
                            invalid={formik.touched.details && Boolean(formik.errors.details)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            outlined
                            className="text-field-width"
                            id="note"
                            name="note"
                            label="Internal note"
                            textarea
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            invalid={formik.touched.note && Boolean(formik.errors.note)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <Checkbox
                            className="text-field-width"
                            id="notifications"
                            name="notifications"
                            label="Notifications"
                            checked={formik.values.notifications}
                            onChange={e => formik.setFieldValue("notifications", !!e.currentTarget.checked)}
                            disabled={notificationsNotAllowedForReason(formik.values.reason)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            outlined
                            className="text-field-width"
                            max={data?.insurance?.parameters.endDate}
                            min={data?.insurance?.parameters.startDate}
                            type="date"
                            id="effectiveDate"
                            name="effectiveDate"
                            label="Effective Date"
                            value={formik.values.effectiveDate}
                            onChange={formik.handleChange}
                            invalid={formik.touched.effectiveDate && Boolean(formik.errors.effectiveDate)}
                        />
                    </div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined type="submit" />
                </div>
                <TypeErrorForms
                    error={cancelInsuranceData?.cancelInsurance.__typename === "CancelInsuranceOutputAlreadyCancelled"}
                    errorMessage={t("CancelInsuranceOutputAlreadyCancelled")}
                />
                <TypeErrorForms
                    error={cancelInsuranceData?.cancelInsurance.__typename === "CancelInsuranceOutputEffectiveDateNotAllowed"}
                    errorMessage={t("CancelInsuranceOutputEffectiveDateNotAllowed")}
                />
                <TypeErrorForms
                    error={cancelInsuranceData?.cancelInsurance.__typename === "CancelInsuranceOutputNotificationsNotAllowed"}
                    errorMessage={t("CancelInsuranceOutputNotificationsNotAllowed")}
                />
                <TypeErrorForms
                    error={cancelInsuranceData?.cancelInsurance.__typename === "CancelInsuranceOutputReasonDetailsNotAllowed"}
                    errorMessage={t("CancelInsuranceOutputReasonDetailsNotAllowed")}
                />
                <GraphQLError error={cancelInsuranceError} />
            </form>
        </div>
    )
}

export default CancelInsurance
