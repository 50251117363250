import { DataTableBody, DataTableCell, DataTableRow } from "@rmwc/data-table"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"

const InsuranceList = ({ insurance }: { insurance?: any[] }) => {
    const { t } = useTranslation()
    let navigate = useNavigate()
    if (!insurance || insurance.length === 0) {
        return <h2>{t("noData")}</h2>
    }
    return (
        <DataTableBody>
            {insurance.map((insurance: any) => {
                return (
                    <DataTableRow key={insurance.id}>
                        <DataTableCell alignEnd onClick={() => navigate(`/insurance/${insurance.id}`, { replace: false })}>
                            {insurance.parameters.homeCountry.name}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/insurance/${insurance.id}`, { replace: false })}>
                            {insurance.parameters.startDate}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/insurance/${insurance.id}`, { replace: false })}>
                            {insurance.parameters.endDate}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/insurance/${insurance.id}`, { replace: false })}>
                            {insurance.status}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`/insurance/${insurance.id}`, { replace: false })}>
                            <IconButton
                                onClick={a => {
                                    a.stopPropagation()
                                    navigate(`/insurance/${insurance.id}`, { replace: false })
                                }}
                                icon="info"
                                label="Insurance"
                            />
                        </DataTableCell>
                    </DataTableRow>
                )
            })}
        </DataTableBody>
    )
}

export default InsuranceList
