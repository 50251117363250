import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";
import { useTranslation } from "react-i18next";

const CancelInsuranceHeader = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
    const { t } = useTranslation()
    return (
        <>
            <Typography style={{ padding: "20px 0px" }} use="headline2">
                {`${t("cancelInsuranceOf")}`}
                <b> {`'${firstName}  ${lastName}'`}</b>
                ...
            </Typography>
        </>
    )
}
export default CancelInsuranceHeader
