import * as React from "react"
import { BrowserRouter as Router, Navigate, Route, Routes } from "react-router-dom"
import AuthGuard from "./shared/Auth"
import Login from "./Login/Login"
import Dashboard from "./Dashboard/Dashboard"
import SyncMember from "./SyncMember/SyncMember"
import ChangeEmailAdress from "./ChangeEmailAdress/ChangeEmailAdress"
import CancelInsurance from "./CancelInsurance/CancelInsurance"
import RevokeCancellation from "./RevokeCancellation/RevokeCancellation"
import PendingQuote from "./PendingQuote/PendingQuote"
import Insurance from "./Insurance/Insurance"
import Member from "./Member/Member"
import Withdrawal from "./Withdrawal/Withdrawal"
import L404 from "./404/404"

const Routing = () => {
    return (
        <React.Fragment>
            <Router>
                <Routes>
                    <Route
                        path={"/"}
                        element={
                            <AuthGuard>
                                <Dashboard />
                            </AuthGuard>
                        }
                    />
                    <Route path={"/login"} element={<Login />} />
                    <Route
                        path={"/sync-member/:id"}
                        element={
                            <AuthGuard>
                                <SyncMember />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path={"/change-email-address/:id"}
                        element={
                            <AuthGuard>
                                <ChangeEmailAdress />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path={"/cancel-insurance/:id"}
                        element={
                            <AuthGuard>
                                <CancelInsurance />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path={"/revoke-cancellation/:id"}
                        element={
                            <AuthGuard>
                                <RevokeCancellation />
                            </AuthGuard>
                        }
                    />
                    <Route
                        path={"/pending-quote/:id"}
                        element={
                            <AuthGuard>
                                <PendingQuote />
                            </AuthGuard>
                        }
                    />
                      <Route
                        path={"/insurance/:id"}
                        element={
                            <AuthGuard>
                                <Insurance />
                            </AuthGuard>
                        }
                    />
                       <Route
                        path={"/member/:id"}
                        element={
                            <AuthGuard>
                                <Member />
                            </AuthGuard>
                        }
                    />
                     <Route
                        path={"/withdraw/:id"}
                        element={
                            <AuthGuard>
                                <Withdrawal />
                            </AuthGuard>
                        }
                    />
                    <Route path="*" element={<L404 />} />
                </Routes>
            </Router>
        </React.Fragment>
    )
}
export default Routing
