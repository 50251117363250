import { useMutation, useQuery } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { Checkbox } from "@rmwc/checkbox"
import "@rmwc/checkbox/styles"
import { List, ListItem } from "@rmwc/list"
import "@rmwc/typography/styles"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import { AcceptInsuranceQuoteMutation, AcceptInsuranceQuoteMutationVariables, InsuranceQuoteQuery, InsuranceQuoteQueryVariables } from "../../generated"
import { Copy } from "../shared/CopyToClipBoard"
import { assert } from "../shared/Error"
import { GraphQLError } from "../shared/GraphQLError"
import PendingQuoteHeader from "../shared/Headers/PendingQuoteHeader"
import Loading from "../shared/Loading"
import { CopyToolTip } from "../shared/ToolTips"
import { TypeErrorForms } from "../shared/TypeError"
import { acceptInsuranceQuoteGQL, insuranceQuoteGQL } from "./PendingQuoteGQL"

const PendingQuote = () => {
    const [accept, setAccept] = useState<boolean>(false)
    const { id } = useParams()
    assert(id)

    let navigate = useNavigate()
    const { t } = useTranslation()

    const { data, loading, error } = useQuery<InsuranceQuoteQuery, InsuranceQuoteQueryVariables>(insuranceQuoteGQL, {
        fetchPolicy: "network-only",
        variables: { id },
    })
    const [acceptInsuranceQuote, { data: acceptInsuranceQuoteData, error: acceptInsuranceQuoteError }] = useMutation<
        AcceptInsuranceQuoteMutation,
        AcceptInsuranceQuoteMutationVariables
    >(acceptInsuranceQuoteGQL)

    if (!data || error) {
        return <GraphQLError error={error} />
    }
    if (loading) {
        return <Loading />
    }
    const submit = async () => {
        const response = await acceptInsuranceQuote({
            variables: {
                input: { id },
            },
        })
        if (response.data?.acceptInsuranceQuote.__typename === "AcceptInsuranceQuoteOutputAccepted") {
            navigate("/?tab=drWalterSyncQueue")
        }
    }

    const quote = data.insuranceQuote
    if (!quote) {
        return <p>{t("quoteNotFound")}</p>
    }
    if (quote.subject.__typename !== "InsuranceSubjectHomeCountryChange") {
        return (
            <TypeErrorForms
                error={acceptInsuranceQuoteData?.acceptInsuranceQuote.__typename === "AcceptInsuranceQuoteOutputQuoteNotFound"}
                errorMessage={t("AcceptInsuranceQuoteOutputQuoteNotFoundCountryChange")}
            />
        )
    }

    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <PendingQuoteHeader firstName={quote.user.firstName} lastName={quote.user.lastName} />
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>{t("from")}:{quote.insurance?.parameters.homeCountry.name}</div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>{t("to")}:{quote.subject.homeCountry.name}</div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    <span style={{ whiteSpace: "pre-line", display: "flex", flexDirection: "column" }}>
                        <span> {t("reason")}:</span> <span>{`${quote.subject.reason}`}</span>
                    </span>
                </ListItem>
                <CopyToolTip name={quote.user.emailAddress}>
                    <ListItem
                        onClick={() => {
                            Copy(quote.user.emailAddress)
                        }}
                    >
                        <div className="mdc-list-item__graphic">4</div>
                        <div>
                            {t("whenInDoubtWriteAt")} {quote.user.emailAddress}
                            {"."}
                        </div>
                    </ListItem>
                </CopyToolTip>
            </List>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>

                <div className="form-item-textfield">
                    <Checkbox
                        className="text-field-width"
                        id="accept"
                        name="accept"
                        label={t("acceptChange")}
                        checked={accept}
                        onChange={e => setAccept(e.currentTarget.checked)}
                    />
                </div>
            </div>
            <div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>
                </div>
                <div className="button-center">
                    <Button disabled={!accept} label={t("confirm")} outlined onClick={submit} />
                </div>
            </div>
            <TypeErrorForms
                error={acceptInsuranceQuoteData?.acceptInsuranceQuote.__typename === "AcceptInsuranceQuoteOutputNotPossible"}
                errorMessage={t("AcceptInsuranceQuoteOutputNotPossible")}
            />
            <TypeErrorForms
                error={acceptInsuranceQuoteData?.acceptInsuranceQuote.__typename === "AcceptInsuranceQuoteOutputQuoteNotFound"}
                errorMessage={t("AcceptInsuranceQuoteOutputQuoteNotFound")}
            />
            <GraphQLError error={acceptInsuranceQuoteError} />
        </div>
    )
}

export default PendingQuote
