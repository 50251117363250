import { Icon } from "@rmwc/icon"
import { Typography } from "@rmwc/typography";
import { useTranslation } from "react-i18next";
import "@rmwc/typography/styles";
import "@rmwc/icon/styles"

const Loading=()=>{
    const { t } = useTranslation()
    return(
        <>
        <Icon icon="loop" />
        <Typography style={{ padding: "20px 0px" }} use="headline2">{t("loading")}</Typography>
        </>
    )
}
export default Loading;