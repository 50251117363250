import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";
import { useTranslation } from "react-i18next";

const ChangeEmailAdressHeader = ({ firstName, lastName, emailAddress }: { firstName: string; lastName: string; emailAddress: string }) => {
    const { t } = useTranslation()
    return (
        <>
            <Typography style={{ padding: "20px 0px" }} use="headline2">
                {`${t("changeEmailAddressOf")}`}
                <b> {` ${firstName}  ${lastName} (${emailAddress})`}</b>...
            </Typography>
        </>
    )
}
export default ChangeEmailAdressHeader
