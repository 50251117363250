import { useQuery } from "@apollo/client"
import "@rmwc/button/styles"
import { DataTable, DataTableContent } from "@rmwc/data-table"
import { List, ListItem, ListItemMeta, ListItemPrimaryText, ListItemSecondaryText, ListItemText } from "@rmwc/list"
import "@rmwc/typography/styles"
import { useTranslation } from "react-i18next"
import { useParams } from "react-router-dom"
import { MemberQuery, MemberQueryVariables } from "../../generated"
import { assert } from "../shared/Error"
import { GenkiDateFormat } from "../shared/FormatDate"
import { GraphQLError } from "../shared/GraphQLError"
import MemberHeader from "../shared/Headers/MemberHeader"
import Loading from "../shared/Loading"
import { IconToolTip } from "../shared/ToolTips"
import InsuranceHeader from "./InsuranceTable/InsuranceHeader"
import InsuranceList from "./InsuranceTable/InsuranceList"
import { memberGQL } from "./MemberGQL"

const Member = () => {
    const { id } = useParams()
    assert(id)
    const { t } = useTranslation()
    const { data, loading, error } = useQuery<MemberQuery, MemberQueryVariables>(memberGQL, {
        fetchPolicy: "network-only",
        variables: { id },
    })
    if (loading) {
        return <Loading />
    }
    if (!data || error) {
        return <GraphQLError error={error} />
    }
    const member = data.member
    if (!member || !member.__typename) {
        return <p>{t("memberNotFound")}</p>
    }
    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <MemberHeader firstName={member.firstName} lastName={member.lastName} />
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>{t("registrationTimestampMember")}: {GenkiDateFormat(new Date(member.registrationTimestamp))}</div>
                </ListItem>

                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>{t("emailAddress")}: {member.emailAddress}</div>
                    <ListItemMeta>
                        {member.emailAddressVerificationTimestamp ? (
                            <IconToolTip text={GenkiDateFormat(new Date(member.emailAddressVerificationTimestamp))} iconName="verified" validityColors={true} />
                        ) : (
                            <IconToolTip text={t("emailIsNotVerified")} iconName="verified" validityColors={false} />
                        )}
                    </ListItemMeta>
                </ListItem>

                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    <div>{t("dateOfBirth")}: {GenkiDateFormat(new Date(member.dateOfBirth))}</div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">4</div>
                    <div>{`${t("userHomeCountry")}: ${member.homeCountry.name}(${member.homeCountry.code})`}</div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">5</div>
                    <div>{t("phoneNumber")}: {member.phoneNumber}</div>
                    <ListItemMeta>
                        {member.phoneNumberVerificationTimestamp ? (
                            <IconToolTip text={GenkiDateFormat(new Date(member.phoneNumberVerificationTimestamp))} iconName="verified" validityColors={true} />
                        ) : (
                            <IconToolTip text={t("phoneNumberIsNotVerified")} iconName="verified" validityColors={false} />
                        )}
                    </ListItemMeta>
                </ListItem>
            </List>
            <DataTable className="table-width">
                <DataTableContent>
                    <InsuranceHeader />
                    <InsuranceList insurance={data.member?.insurances} />
                </DataTableContent>
            </DataTable>
        </div>
    )
}

export default Member
