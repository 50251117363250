import { Typography } from "@rmwc/typography"

export const TypeErrorForms = ({ error, errorMessage }: { error: boolean; errorMessage: string }) => {
    if (error) {
        return (
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography className="error" use="headline5">
                    {errorMessage}
                </Typography>
            </div>
        )
    }
    return <></>
}

export const TypeErrorLogin = ({ error, errorMessage }: { error: boolean; errorMessage: string }) => {
    if (error) {
        return (
            <div className="login-items-wrapper">
                <Typography className="error" use="headline5">
                    {errorMessage}
                </Typography>
            </div>
        )
    }
    return <></>
}
