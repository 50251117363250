import { gql } from "@apollo/client"

export const signInGQL = gql`
    mutation signIn($emailAddress: EmailAddress!, $password: Password!) {
        signIn(input: { emailAddress: $emailAddress, password: $password }) {
            __typename
            ... on SignInOutputInvalidCredentials {
                __typename
            }
            ... on SignInOutputAuthenticated {
                admin {
                    emailAddress
                    firstName
                    lastName
                    id
                }
            }
        }
    }
`
