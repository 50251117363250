import { useMutation } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { List, ListItem, ListItemMeta } from "@rmwc/list"
import "@rmwc/list/styles"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import { useFormik } from "formik"
import { FunctionComponent, ReactElement } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import * as yup from "yup"
import { DrwalterInsurancePlan, InsuranceRegion, RegisterDrwalterInsuranceMutation, RegisterDrwalterInsuranceMutationVariables } from "../../../generated"
import { Copy } from "../../shared/CopyToClipBoard"
import { DrWalterDateFormat } from "../../shared/FormatDate"
import { GraphQLError } from "../../shared/GraphQLError"
import SyncMemberCasesHeader from "../../shared/Headers/SyncMemberCasesHeader"
import { AddInsuranceRegex } from "../../shared/Regex"
import { CopyToolTip } from "../../shared/ToolTips"
import { registerDrwalterInsuranceGQL } from "../SyncMemberGQL"

type periodType = { endInclusive: string; start: string }

const regExp = new RegExp("https://www\\.dr-walter-gmbh\\.com/miaplus/versicherungen/vers_detail/(\\d+)\\.html")

const validationSchema = yup.object({
    id: yup.string().required("Url is required").matches(regExp, "Url must be in the right format"),
})

const DrWalterPlan: React.CSSProperties = {
    display: "flex",
    gap: "5px",
}
const boldStyle: React.CSSProperties = {
    fontWeight: "bold",
}
function planToString(plan: DrwalterInsurancePlan): ReactElement {
    switch (plan) {
        case DrwalterInsurancePlan.Age_18To_29Deductible_0:
            return (
                <>
                    <>⚠️</>
                    <div style={boldStyle}>{"[3] up to 29 - without deductible"}</div>
                </>
            )
        case DrwalterInsurancePlan.Age_18To_29Deductible_50:
            return <div>{"[4] up to 29 - with deductible"}</div>
        case DrwalterInsurancePlan.Age_30To_49Deductible_0:
            return (
                <>
                    <>⚠️</>
                    <div style={boldStyle}>{"[2] 30 to 49 - without deductible"}</div>
                </>
            )
        case DrwalterInsurancePlan.Age_30To_49Deductible_50:
            return <div>{"[1] 30 to 49 - with deductible"}</div>
    }
}

type TravelingUSACanLableProps = {
    isTravelingUSACanada: boolean,
    optionLabel: string
}

export const TravelingUSACanLabel: FunctionComponent<TravelingUSACanLableProps> = ({ isTravelingUSACanada, optionLabel }) => {
    if (isTravelingUSACanada) {
        return <div><b> {"⚠️ Check"} '{optionLabel}'</b> </div>                        
    }
    return <div> {"Do not check"} '{optionLabel}' </div>
}

const AddInsurance = ({
    id,
    firstName,
    lastName,
    period,
    includeCanadaAndUSA,
    homeCountry,
    homeCountryCode,
    plan,
    genkiEffectiveDate,
    personId,
}: {
    id: string
    firstName: string
    lastName: string
    period: periodType
    includeCanadaAndUSA: boolean
    homeCountry: string
    homeCountryCode: string
    plan: DrwalterInsurancePlan
    genkiEffectiveDate: string
    personId: string
}) => {
    const [registerDrwalterInsurance, { data, error }] = useMutation<RegisterDrwalterInsuranceMutation, RegisterDrwalterInsuranceMutationVariables>(
        registerDrwalterInsuranceGQL
    )

    let navigate = useNavigate()
    const { t } = useTranslation()
    const formik = useFormik({
        initialValues: {
            id: "",
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            const response = await registerDrwalterInsurance({
                variables: {
                    input: {
                        genkiEffectiveDate: genkiEffectiveDate,
                        genkiId: id,
                        homeCountryCode: homeCountryCode,
                        id: AddInsuranceRegex(values.id),
                        isWithdrawn: false,
                        period: period,
                        plan: plan,
                        region: includeCanadaAndUSA ? InsuranceRegion.Worldwide : InsuranceRegion.WorldwideExcludingCanadaAndUsa,
                    },
                },
            })
            if (response.data?.registerDrwalterInsurance.__typename === "RegisterDrwalterInsuranceOutputRegistered") {
                navigate(0)
            }
        },
    })
    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <SyncMemberCasesHeader firstName={firstName} lastName={lastName} />
            </div>

            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography use="subtitle1">{t("addInsurance")}</Typography>
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>
                        <a href="https://www.dr-walter-gmbh.com/miaplus/?l=en" target="_blank">
                            {t("signIn")}
                        </a>{" "}
                        {t("withCorrectAccount")}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>
                        {t("goTo") + " "}
                        <a
                            href={`https://www.dr-walter-gmbh.com/miaplus/personen/pers_detail/${personId.replace("drwalter person:", "")}.html?l=en`}
                            target="_blank"
                        >
                            {firstName + " " + lastName}
                        </a>
                        .
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    <div>
                        {t("click")} <b>{t("enterInsurance")}</b>
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">4</div>
                    <div>
                        <b>{t("insuredPerson")}:</b> {t("leaveUnchanged")}
                    </div>
                </ListItem>
                <CopyToolTip name={DrWalterDateFormat(new Date(period.start))}>
                    <ListItem
                        onClick={() => {
                            Copy(DrWalterDateFormat(new Date(period.start)))
                        }}
                    >
                        <div className="mdc-list-item__graphic">5</div>
                        <div>
                            <b>{t("startDate")}:</b> {DrWalterDateFormat(new Date(period.start))}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy Start date"
                                onClick={() => {
                                    Copy(DrWalterDateFormat(new Date(period.start)))
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <CopyToolTip name={DrWalterDateFormat(new Date(period.endInclusive))}>
                    <ListItem
                        onClick={() => {
                            Copy(DrWalterDateFormat(new Date(period.endInclusive)))
                        }}
                    >
                        <div className="mdc-list-item__graphic">6</div>
                        <div>
                            <b>{t("endDate")}:</b> {DrWalterDateFormat(new Date(period.endInclusive))}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy End date"
                                onClick={() => {
                                    Copy(DrWalterDateFormat(new Date(period.endInclusive)))
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <ListItem>
                    <div className="mdc-list-item__graphic">7</div>
                    <TravelingUSACanLabel isTravelingUSACanada={includeCanadaAndUSA} optionLabel={t("persTravelingUSACan")} />
                </ListItem>
                <CopyToolTip name={homeCountry}>
                    <ListItem
                        onClick={() => {
                            Copy(homeCountry)
                        }}
                    >
                        <div className="mdc-list-item__graphic">8</div>
                        <div>
                            <b>{t("hCountry")}:</b> {homeCountry}
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy Home country"
                                onClick={() => {
                                    Copy(homeCountry)
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <CopyToolTip name={"worldwide"}>
                    <ListItem
                        onClick={() => {
                            Copy(includeCanadaAndUSA ? t("travelDestinationWorldWide") : t("travelDestinationWorldWideExcludingCanadaAndUSA"))
                        }}
                    >
                        <div className="mdc-list-item__graphic">9</div>
                        <div>
                            <b>{t("travelDest")}:</b> {includeCanadaAndUSA ? t("travelDestinationWorldWide") : t("travelDestinationWorldWideExcludingCanadaAndUSA")} 
                        </div>
                        <ListItemMeta>
                            <IconButton
                                className="padding-is-0"
                                icon="content_copy"
                                label="Copy Travel destination"
                                onClick={() => {
                                    Copy("worldwide")
                                }}
                            />
                        </ListItemMeta>
                    </ListItem>
                </CopyToolTip>
                <ListItem>
                    <div className="mdc-list-item__graphic">10</div>
                    <div style={DrWalterPlan}>
                        <b>{t("insurance")}:</b> {planToString(plan)}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">11</div>
                    <div>
                        <b>{t("costCenter")}: </b> leave blank
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">12</div>
                    <div>
                        {t("click")} <b>{t("saveInsur")}</b>
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">13</div>
                    {t("enterUrlToInsur")}:
                </ListItem>
            </List>
            <form onSubmit={formik.handleSubmit} noValidate>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            type="url"
                            autoComplete="off"
                            outlined
                            className="text-field-width"
                            id="id"
                            name="id"
                            value={formik.values.id}
                            onChange={formik.handleChange}
                            invalid={formik.touched.id && Boolean(formik.errors.id)}
                            helpText={{
                                persistent: true,
                                children:
                                    formik.touched.id && Boolean(formik.errors.id)
                                        ? formik.touched.id && formik.errors.id
                                        : "Example: https://www.dr-walter-gmbh.com/miaplus/versicherungen/vers_detail/12345678.html",
                            }}
                        />
                    </div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined type="submit" />
                </div>
                <GraphQLError error={error} />
            </form>
        </div>
    )
}

export default AddInsurance
