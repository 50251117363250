import { useState, InputHTMLAttributes } from "react"
import { TextField, TextFieldProps } from "@rmwc/textfield"
import { Icon } from "@rmwc/icon"
import "@rmwc/textfield/styles"
import "@rmwc/icon/styles"
const IconButton = ({ textFieldType, onClick }: { textFieldType: "text" | "password"; onClick: any }) => {
    if (textFieldType === "text") {
        return <Icon icon="visibility" onClick={onClick} />
    }
    if (textFieldType === "password") {
        return <Icon icon="visibility_off" onClick={onClick} />
    }
    return <Icon icon="visibility_off" onClick={onClick} />
}


const PasswordTextField = (props: any) => {
    const [textFieldType, setTextFieldType] = useState<"text" | "password">("password")
    const textFieldTypeChange = () => {
        if (textFieldType === "text") {
            setTextFieldType("password")
        }
        if (textFieldType === "password") {
            setTextFieldType("text")
        }
    }

    return (
        <>
            <TextField
                {...props}
                type={textFieldType}
                trailingIcon={{
                    icon: textFieldType === "text" ? "visibility" : "visibility_off",
                    tabIndex: 0,
                    onClick: () => textFieldTypeChange(),
                }}
            />
        </>
    )
}
export default PasswordTextField
