import { gql } from "@apollo/client"

export const insuranceCancellationGQL = gql`
    query insuranceCancellation($id: ID!) {
        insuranceCancellation(id: $id) {
            id
            insurance {
                id
                parameters {
                    firstName
                    lastName
                }
            }
        }
    }
`

export const revokeInsuranceCancellationGQL = gql`
    mutation revokeInsuranceCancellation($input: RevokeInsuranceCancellationInput!) {
        revokeInsuranceCancellation(input: $input) {
            ... on RevokeInsuranceCancellationOutputRevoked {
                __typename
            }
        }
    }
`
