import { useMutation } from "@apollo/client"
import "@rmwc/button/styles"
import "@rmwc/icon-button/styles"
import { List, ListItem } from "@rmwc/list"
import "@rmwc/list/styles"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import {
    CompleteUploadMutation,
    CompleteUploadMutationVariables,
    PrepareUploadMutation,
    PrepareUploadMutationVariables,
    ProvideDrwalterInsuranceCertificateMutation,
    ProvideDrwalterInsuranceCertificateMutationVariables,
} from "../../../generated"
import { GraphQLError } from "../../shared/GraphQLError"
import SyncMemberCasesHeader from "../../shared/Headers/SyncMemberCasesHeader"
import { ProvideInsuranceRegex } from "../../shared/Regex"
import { TypeErrorForms } from "../../shared/TypeError"
import { completeUploadGQL, prepareUploadGQL, provideDrwalterInsuranceCertificateGQL } from "../SyncMemberGQL"

const ProvideInsuranceCertificate = ({
    firstName,
    lastName,
    insuranceId,
    agreementId,
}: {
    firstName: string
    lastName: string
    insuranceId: string
    agreementId: string
}) => {
    let navigate = useNavigate()
    const { t } = useTranslation()
    const [prepareUpload, { data, error }] = useMutation<PrepareUploadMutation, PrepareUploadMutationVariables>(prepareUploadGQL)
    const [completeUpload] = useMutation<CompleteUploadMutation, CompleteUploadMutationVariables>(completeUploadGQL)
    const [provideDrwalterInsuranceCertificate] = useMutation<
        ProvideDrwalterInsuranceCertificateMutation,
        ProvideDrwalterInsuranceCertificateMutationVariables
    >(provideDrwalterInsuranceCertificateGQL)

    const handleFileChange = (fileList: any) => {
        let fileId = ProvideInsuranceRegex(insuranceId)
        if (!fileList) return
        if (fileList[0].name.includes(fileId)) {
            const insuranceFile = fileList[0]
            UploadFileLogic(insuranceFile)
        } else {
            alert(`The file '${fileList[0].name}' doesn't seem to be the right one. Did you rename it when downloading?`)
        }
    }

    const UploadFileLogic = async (insuranceFile: File) => {
        if (insuranceFile !== undefined) {
            let fetchUrl = ""
            const prepareUploadResponse = await prepareUpload({
                variables: {
                    input: {
                        contentType: insuranceFile.type,
                        name: insuranceFile.name,
                        size: insuranceFile.size,
                    },
                },
            })
            if (prepareUploadResponse.data?.prepareUpload.__typename === "PrepareUploadOutputPrepared") {
                fetchUrl = prepareUploadResponse.data.prepareUpload.url
                const headers = new Headers({
                    "Content-Length": insuranceFile.size.toString(),
                })

                await fetch(fetchUrl, {
                    method: "POST",
                    headers: headers,
                    body: insuranceFile,
                })
                    .then(response => {})
                    .catch(e => {
                        fetchUrl = ""
                    })
            }
            if (fetchUrl) {
                const completeUploadResponse = await completeUpload({
                    variables: {
                        input: {
                            url: fetchUrl,
                        },
                    },
                })
                if (
                    prepareUploadResponse.data?.prepareUpload.__typename === "PrepareUploadOutputPrepared" &&
                    completeUploadResponse.data?.completeUpload.__typename === "CompleteUploadOutputCompleted"
                ) {
                    const provideDrwalterInsuranceCertificateResponse = await provideDrwalterInsuranceCertificate({
                        variables: {
                            input: {
                                agreementId: agreementId,
                                uploadId: completeUploadResponse.data.completeUpload.upload.id,
                            },
                        },
                    })

                    if (
                        provideDrwalterInsuranceCertificateResponse.data?.provideDrwalterInsuranceCertificate.__typename ===
                        "ProvideDrwalterInsuranceCertificateOutputProvided"
                    ) {
                        navigate(0)
                    }
                }
            }
        } else {
            alert("Please select a file!")
        }
    }

    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <SyncMemberCasesHeader firstName={firstName} lastName={lastName} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography use="subtitle1">{t("provideInsuranceCertificate")}</Typography>
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>
                        <a href="https://www.dr-walter-gmbh.com/miaplus/?l=en" target="_blank">
                            {t("signIn")}
                        </a>{" "}
                        {t("withCorrectAccount")}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>
                        {t("downloadThe")}{" "}
                        <a href={`https://www.dr-walter-gmbh.com/miaplus/spool.php?vers=${insuranceId.replace("drwalter insurance:", "")}`} target="_blank">
                            {t("insurCert")}
                        </a>{" "}
                        {t("fileWithoutRename")}.
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    {t("selectDownFile")}:
                </ListItem>
            </List>
            <div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <input type="file" accept=".pdf" id="file" name="file" onChange={e => handleFileChange(e.target.files)} />
                    </div>
                </div>
            </div>
            <TypeErrorForms
                error={data?.prepareUpload.__typename === "PrepareUploadOutputInvalidContentType"}
                errorMessage={t("PrepareUploadOutputInvalidContentType")}
            />
            <TypeErrorForms error={data?.prepareUpload.__typename === "PrepareUploadOutputInvalidName"} errorMessage={t("PrepareUploadOutputInvalidName")} />
            <TypeErrorForms error={data?.prepareUpload.__typename === "PrepareUploadOutputInvalidSize"} errorMessage={t("PrepareUploadOutputInvalidSize")} />
            <GraphQLError error={error} />
        </div>
    )
}

export default ProvideInsuranceCertificate
