import { useLazyQuery } from "@apollo/client"
import { DataTable, DataTableContent } from "@rmwc/data-table"
import "@rmwc/data-table/styles"
import { Tab, TabBar } from "@rmwc/tabs"
import "@rmwc/tabs/styles"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSearchParams } from "react-router-dom"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import {
    DrwalterSyncQueueQuery,
    DrwalterSyncQueueQueryVariables,
    InsuranceQuotesWithPendingProviderDecisionQuery,
    InsuranceQuotesWithPendingProviderDecisionQueryVariables,
    MembersQuery,
    MembersQueryVariables,
} from "../../generated"
import "./dashboard.scss"
import { allMembersGQL, drwalterSyncQueueGQL, insuranceQuotesWithPendingProviderDecisionGQL } from "./dashboardGQL"
import InsuranceQuoteHeader from "./InsuranceQuotesTable/InsuranceQuoteHeader"
import Loading from "../shared/Loading"
import InsuranceQuoteList from "./InsuranceQuotesTable/InsuranceQuoteList"
import MembersHeader from "./MembersTable/MembersHeader"
import MembersList from "./MembersTable/MembersList"
import { sortOrderType } from "./types"

function nameToTab(name?: string): number {
    switch (name) {
        case "members":
            return 0
        case "drWalterSyncQueue":
            return 1
        case "changeRequest":
            return 2
        default:
            return -1
    }
}

function tabToName(tab: number|null): string {
    switch (tab) {
        case 0:
            return "members"
        case 1:
            return "drWalterSyncQueue"
        case 2:
            return "changeRequest"
        default:
            return "drWalterSyncQueue"
    }
}

const searchArray = (toSearch: string, array: any[]) => {
    let terms = toSearch.split(" ")
    return array.filter(object =>
        terms.every(term => Object.values(object).some((value: any) => (typeof value === "string" || value instanceof String ? value.includes(term) : false)))
    )
}

const Dashboard = () => {
    const { t } = useTranslation()
    let [searchParams, setSearchParams] = useSearchParams()
    let tabName = searchParams.get("tab")
    const [table, setTable] = useState<number>(1)
    const [search, setSearch] = useState<string>("")
    useEffect(() => {
        if (tabName !== null) {
            const tab = nameToTab(tabName)
            if (tab >= 0) {
                setTable(tab)
            }
        }
        switch (tabName) {
            case "members":
                if (!allMembersData) {
                    loadMembers()
                }
                break
            case "drWalterSyncQueue":
                if (!drwalterSyncQueueData) {
                    loadQueue()
                }
                break
            case "changeRequest":
                if (!insuranceQuotesData) {
                    loadInsurance()
                }
                break
            case "":
                if (!drwalterSyncQueueData) {
                    loadQueue()
                }
                break
            default:
                if (!allMembersData || !drwalterSyncQueueData || !insuranceQuotesData) {
                    loadMembers()
                    loadQueue()
                    loadInsurance()
                }
                break
        }
    }, [tabName])
    const [loadMembers, { data: allMembersData, loading: allMembersLoading }] = useLazyQuery<MembersQuery, MembersQueryVariables>(allMembersGQL, {
        fetchPolicy: "cache-and-network",
    })
    const [loadQueue, { data: drwalterSyncQueueData, loading: drwalterSyncQueueLoading }] = useLazyQuery<
        DrwalterSyncQueueQuery,
        DrwalterSyncQueueQueryVariables
    >(drwalterSyncQueueGQL, {
        fetchPolicy: "cache-and-network",
    })
    const [loadInsurance, { data: insuranceQuotesData, loading: insuranceQuotesLoading }] = useLazyQuery<
        InsuranceQuotesWithPendingProviderDecisionQuery,
        InsuranceQuotesWithPendingProviderDecisionQueryVariables
    >(insuranceQuotesWithPendingProviderDecisionGQL, {
        fetchPolicy: "cache-and-network",
    })
    const [sortOrder, setSortOrder] = useState<sortOrderType>({ objectKey: "name", order: 1 })
    const onActivateTabBar = (number: number) => {
        const tabName = tabToName(number)
        if (tabName !== "") {
            setSearchParams({ tab: tabName })
        }
        setTable(number)
    }
    if (
        (allMembersLoading && !allMembersData && table === 0) ||
        (drwalterSyncQueueLoading && !drwalterSyncQueueData && table === 1) ||
        (insuranceQuotesLoading && !insuranceQuotesData && table === 2)
    ) {
        return (
            <div className="dashboard-wrapper">
                <div className="header-padding">
                    <div className="tab-wrapper">
                        <TabBar activeTabIndex={table} onActivate={evt => onActivateTabBar(evt.detail.index)}>
                            <Tab>{t("members")}</Tab>
                            <Tab>{t("drWalterSyncQueue")}</Tab>
                            <Tab>{t("changeRequest")}</Tab>
                        </TabBar>
                    </div>
                </div>
                <Loading />
            </div>
        )
    }
    return (
        <div className="dashboard-wrapper">
            <div className="header-padding">
                <div className="tab-wrapper">
                    <TabBar activeTabIndex={table} onActivate={evt => onActivateTabBar(evt.detail.index)}>
                        <Tab>{t("members")}</Tab>
                        <Tab>{t("drWalterSyncQueue")}</Tab>
                        <Tab>{t("changeRequest")}</Tab>
                    </TabBar>
                </div>
                <TextField
                    outlined
                    className="text-field-width"
                    id="search"
                    name="search"
                    label="Search"
                    value={search}
                    onChange={e => setSearch(e.currentTarget.value)}
                />
            </div>
            <DataTable>
                <DataTableContent className="table-width">
                    {(table === 0 || table === 1) &&
                    !allMembersLoading &&
                    !drwalterSyncQueueLoading &&
                    (allMembersData?.members.length !== 0 || drwalterSyncQueueData?.drwalterSyncQueue.length !== 0) ? (
                        <MembersHeader sortOrder={sortOrder} setSortOrder={setSortOrder} />
                    ) : (
                        <></>
                    )}

                    {table === 2 && !insuranceQuotesLoading && insuranceQuotesData?.insuranceQuotesWithPendingProviderDecision.length !== 0 ? (
                        <InsuranceQuoteHeader sortOrder={sortOrder} setSortOrder={setSortOrder} />
                    ) : (
                        <></>
                    )}
                    {table === 0 && !allMembersLoading ? (
                        <MembersList sortOrder={sortOrder} members={allMembersData ? searchArray(search, allMembersData.members) : []} />
                    ) : (
                        <></>
                    )}
                    {table === 1 && !drwalterSyncQueueLoading ? (
                        <MembersList
                            sortOrder={sortOrder}
                            members={drwalterSyncQueueData ? searchArray(search, drwalterSyncQueueData.drwalterSyncQueue) : []}
                            sync
                        />
                    ) : (
                        <></>
                    )}
                    {table === 2 && !insuranceQuotesLoading ? (
                        <InsuranceQuoteList
                            sortOrder={sortOrder}
                            insuranceQuotes={insuranceQuotesData ? searchArray(search, insuranceQuotesData.insuranceQuotesWithPendingProviderDecision) : []}
                        />
                    ) : (
                        <></>
                    )}
                </DataTableContent>
            </DataTable>
        </div>
    )
}

export default Dashboard
