import { useMutation } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import "@rmwc/icon-button/styles"
import { List, ListItem } from "@rmwc/list"
import "@rmwc/list/styles"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { DrwalterInsurancePlan, InsuranceRegion, RegisterDrwalterInsuranceMutation, RegisterDrwalterInsuranceMutationVariables } from "../../../generated"
import { GraphQLError } from "../../shared/GraphQLError"
import SyncMemberCasesHeader from "../../shared/Headers/SyncMemberCasesHeader"
import { registerDrwalterInsuranceGQL } from "../SyncMemberGQL"

type periodType = { endInclusive: string; start: string }

const WithdrawInsurance = ({
    id,
    genkiId,
    firstName,
    lastName,
    emailAddress,
    period,
    region,
    homeCountryCode,
    plan,
    genkiEffectiveDate,
}: {
    id: string
    genkiId: string
    firstName: string
    lastName: string
    emailAddress: string
    period: periodType
    region: InsuranceRegion
    homeCountryCode: string
    plan: DrwalterInsurancePlan
    genkiEffectiveDate: string
}) => {
    const [registerDrwalterInsurance, { data, error }] = useMutation<RegisterDrwalterInsuranceMutation, RegisterDrwalterInsuranceMutationVariables>(
        registerDrwalterInsuranceGQL
    )
    let navigate = useNavigate()
    const { t } = useTranslation()
    const properId = id.split(':').pop() ?? id
    const link = `https://www.dr-walter-gmbh.com/miaplus/versicherungen/vers_detail/${properId}.html`
    const subject = `(NUR EIN TEST) Widerruf Versicherung ${properId} (${firstName})`
    const body = `Hallo Patricia,\n\n 
            unser Mitglied ${firstName} (${emailAddress}) hat die folgende widerrufen:\n${link}\n\n 
            Gruß`

    async function submit() {
        const response = await registerDrwalterInsurance({
            variables: {
                input: {
                    genkiEffectiveDate: genkiEffectiveDate,
                    genkiId: genkiId,
                    homeCountryCode: homeCountryCode,
                    id: properId,
                    isWithdrawn: true,
                    period: period,
                    plan: plan,
                    region: region,
                },
            },
        })
        if (response.data?.registerDrwalterInsurance.__typename === "RegisterDrwalterInsuranceOutputRegistered") {
            navigate(0)
        }
    }

    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <SyncMemberCasesHeader firstName={firstName} lastName={lastName} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <Typography use="subtitle1">{t("withdrawInsurance")}</Typography>
            </div>
            <List twoLine>
                <ListItem>
                    <div className="mdc-list-item__graphic">1</div>
                    <div>
                        <a href="https://www.dr-walter-gmbh.com/miaplus/?l=en" target="_blank">
                            {t("signIn")}
                        </a>{" "}
                        {t("withCorrectAccount")}
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">2</div>
                    <div>
                        <a href={`https://www.dr-walter-gmbh.com/miaplus/versicherungen/vers_storno.html?versicherung_id=${properId}&l=en`} target="_blank">
                            {t("cancelInsur")}
                        </a>{" "}
                        {t("ifStillPoss")}.
                    </div>
                </ListItem>
                <ListItem>
                    <div className="mdc-list-item__graphic">3</div>
                    <div>
                        <a href={`mailto:cuerten@dr-walter.com?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`} target="_blank">
                            {t("notifyPatricia")}
                        </a>
                        {" "}
                        {t("fromDrWaltAbWithdraw")}.
                    </div>
                </ListItem>
            </List>
            <div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined onClick={submit} />
                </div>
                <GraphQLError error={error} />
            </div>
        </div>
    )
}

export default WithdrawInsurance
