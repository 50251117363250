import { useMutation, useQuery } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import { Typography } from "@rmwc/typography"
import "@rmwc/typography/styles"
import { useFormik } from "formik"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import {
    InsuranceCancellationQuery,
    InsuranceCancellationQueryVariables,
    RevokeInsuranceCancellationMutation,
    RevokeInsuranceCancellationMutationVariables,
} from "../../generated"
import { BreadCrumb } from "../shared/BreadCrumbs"
import { assert } from "../shared/Error"
import { GraphQLError } from "../shared/GraphQLError"
import RevokeCancellationHeader from "../shared/Headers/RevokeCancellationHeader"
import Loading from "../shared/Loading"
import { TypeErrorForms } from "../shared/TypeError"
import { insuranceCancellationGQL, revokeInsuranceCancellationGQL } from "./RevokeCancellationGQL"

const validationSchema = yup.object({
    note: yup.string().required("Note is required"),
})
const RevokeCancellation = () => {
    const { id } = useParams()
    assert(id)
    let navigate = useNavigate()
    const { t } = useTranslation()

    const { data, loading, error } = useQuery<InsuranceCancellationQuery, InsuranceCancellationQueryVariables>(insuranceCancellationGQL, {
        fetchPolicy: "network-only",
        variables: { id },
    })
    const [revokeInsurance, { data: revokeInsuranceData, error: revokeInsuranceError }] = useMutation<
        RevokeInsuranceCancellationMutation,
        RevokeInsuranceCancellationMutationVariables
    >(revokeInsuranceCancellationGQL)

    const formik = useFormik({
        initialValues: {
            note: "",
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            const response = await revokeInsurance({
                variables: {
                    input: {
                        internalNote: values.note,
                        id: id,
                    },
                },
            })
            if (response.data?.revokeInsuranceCancellation.__typename === "RevokeInsuranceCancellationOutputRevoked") {
                navigate(`/insurance/${data?.insuranceCancellation?.insurance.id}`)
            }
        },
    })

    if (!data || error) {
        return <GraphQLError error={error} />
    }
    if (loading) {
        return <Loading />
    }
    const insuranceCancellation = data.insuranceCancellation
    if (!insuranceCancellation) {
        return <p>{t("insuranceCancellationNotFound")}</p>
    }

    return (
        <div className="list-wrapper">
            {" "}
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <BreadCrumb name={t("insurance")} to={`/insurance/${insuranceCancellation.insurance.id}`} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <RevokeCancellationHeader
                    firstName={insuranceCancellation.insurance.parameters.firstName}
                    lastName={insuranceCancellation.insurance.parameters.lastName}
                />
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            outlined
                            className="text-field-width"
                            id="note"
                            name="note"
                            label="Internal note"
                            textarea
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            invalid={formik.touched.note && Boolean(formik.errors.note)}
                        />
                    </div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined type="submit" />
                </div>
                {revokeInsuranceData?.revokeInsuranceCancellation.__typename === "RevokeInsuranceCancellationOutputAlreadyRevoked" ? (
                    <div className="form-item">
                        <div className="mdc-list-item__graphic"></div>
                        <Typography className="error" use="headline5">
                            {t("RevokeInsuranceCancellationOutputAlreadyRevoked")}
                        </Typography>
                    </div>
                ) : (
                    <></>
                )}
                <TypeErrorForms
                    error={revokeInsuranceData?.revokeInsuranceCancellation.__typename === "RevokeInsuranceCancellationOutputCancellationNotFound"}
                    errorMessage={t("RevokeInsuranceCancellationOutputCancellationNotFound")}
                />
                <TypeErrorForms
                    error={revokeInsuranceData?.revokeInsuranceCancellation.__typename === "RevokeInsuranceCancellationOutputTooLate"}
                    errorMessage={t("RevokeInsuranceCancellationOutputTooLate")}
                />
                <TypeErrorForms
                    error={revokeInsuranceData?.revokeInsuranceCancellation.__typename === "RevokeInsuranceCancellationOutputInsuranceWithdrawn"}
                    errorMessage={t("RevokeInsuranceCancellationOutputInsuranceWithdrawn")}
                />
                <GraphQLError error={revokeInsuranceError} />
            </form>
        </div>
    )
}

export default RevokeCancellation
