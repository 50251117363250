import { useMutation } from "@apollo/client"
import { Navigate } from "react-router-dom"
import { signOutGQL } from "./LogoutGQL"

const Logout = () => {
    const [deAuthenticate, { loading }] = useMutation(signOutGQL)
    deAuthenticate()
    return loading ? <Navigate to="/login" /> : <></>
}

export default Logout;