import { DataTableBody, DataTableCell, DataTableRow } from "@rmwc/data-table"
import { IconButton } from "@rmwc/icon-button"
import "@rmwc/icon-button/styles"
import { useMemo } from "react"
import { useTranslation } from "react-i18next"
import {  useNavigate } from "react-router-dom"
import { error } from "../../shared/Error"
import { sort } from "../../shared/Sort"
import { insuranceQuoteType, sortOrderType } from "../types"

function insuranceSubjectTypeName(typename: string, homeCountryChange: string, newInsurance: string, regionChange: string) {
    switch (typename) {
        case "InsuranceSubjectHomeCountryChange":
            return homeCountryChange
        case "InsuranceSubjectNewInsurance":
            return newInsurance
        case "InsuranceSubjectRegionChange":
            return regionChange
        default:
            error()
    }
}

const InsuranceQuoteList = ({ insuranceQuotes, sortOrder }: { insuranceQuotes: insuranceQuoteType[]; sortOrder: sortOrderType }) => {
    const { t } = useTranslation()
    let navigate = useNavigate()
    const memoizedInsuranceQuotes = useMemo(() => sort(insuranceQuotes, sortOrder), [insuranceQuotes, sortOrder])

    if (insuranceQuotes.length === 0) {
        return <h2>{t("noData")}</h2>
    }
    return (
        <DataTableBody>
            {memoizedInsuranceQuotes.map((insuranceQuotes: insuranceQuoteType) => {
                return (
                    <DataTableRow key={insuranceQuotes.id}>
                        <DataTableCell alignEnd onClick={() => navigate(`pending-quote/${insuranceQuotes.id}`, { replace: false })}>
                            {`${insuranceQuotes.user.firstName} ${insuranceQuotes.user.lastName}`}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`pending-quote/${insuranceQuotes.id}`, { replace: false })}>
                            {insuranceQuotes.user.emailAddress}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`pending-quote/${insuranceQuotes.id}`, { replace: false })}>
                            {insuranceQuotes.insurance?.parameters.homeCountry.name}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`pending-quote/${insuranceQuotes.id}`, { replace: false })}>
                            {insuranceSubjectTypeName(insuranceQuotes.subject.__typename, t("homeCountryChange"), t("newInsurance"), t("regionChange"))}
                        </DataTableCell>
                        <DataTableCell alignEnd onClick={() => navigate(`pending-quote/${insuranceQuotes.id}`, { replace: false })}>
                                <IconButton
                                    onClick={e => {
                                        e.stopPropagation()
                                        navigate(`pending-quote/${insuranceQuotes.id}`, { replace: false })
                                    }}
                                    icon="sync_alt"
                                    label="Cancel Insurance"
                                />
                        </DataTableCell>
                    </DataTableRow>
                )
            })}
        </DataTableBody>
    )
}

export default InsuranceQuoteList
