import { useMutation, useQuery } from "@apollo/client"
import { Button } from "@rmwc/button"
import "@rmwc/button/styles"
import { Checkbox } from "@rmwc/checkbox"
import "@rmwc/checkbox/styles"
import "@rmwc/icon-button/styles"
import "@rmwc/list/styles"
import { Select } from "@rmwc/select"
import "@rmwc/select/styles"
import { TextField } from "@rmwc/textfield"
import "@rmwc/textfield/styles"
import "@rmwc/typography/styles"
import { useFormik } from "formik"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import {
    InsuranceQuery,
    InsuranceQueryVariables,
    InsuranceWithdrawalReason,
    WithdrawInsuranceAgreementMutation,
    WithdrawInsuranceAgreementMutationVariables,
} from "../../generated"
import { insuranceGQL } from "../CancelInsurance/CancelInsuranceGQL"
import { BreadCrumb } from "../shared/BreadCrumbs"
import { assert } from "../shared/Error"
import { GraphQLError } from "../shared/GraphQLError"
import WithdrawInsuranceHeader from "../shared/Headers/WithdrawInsuranceHeader"
import Loading from "../shared/Loading"
import { TypeErrorForms } from "../shared/TypeError"
import "./Withdrawal.scss"
import { withdrawInsuranceAgreementGQL } from "./WithdrawalGQL"

interface InsuranceWithdrawalReasonArrType {
    label: string
    value: InsuranceWithdrawalReason
}
const InsuranceWithdrawalReasonArr: InsuranceWithdrawalReasonArrType[] = [
    { label: "Breach of contract", value: InsuranceWithdrawalReason.BreachOfContract },
    { label: "I needed a certificate for immigration", value: InsuranceWithdrawalReason.CertificateOnly },
    { label: "I entered incorrect data", value: InsuranceWithdrawalReason.IncorrectData },
    { label: "insurance fraud", value: InsuranceWithdrawalReason.InsuranceFraud },
    { label: "Not specified", value: InsuranceWithdrawalReason.NotSpecified },
    { label: "My travel plans changed", value: InsuranceWithdrawalReason.NotTraveling },
    { label: "I have other reasons", value: InsuranceWithdrawalReason.Other },
    { label: "I’m covered by another insurance", value: InsuranceWithdrawalReason.OtherInsurance },
    { label: "Payment fraud", value: InsuranceWithdrawalReason.PaymentFraud },
    { label: "I need insurance that fits me better", value: InsuranceWithdrawalReason.WrongProduct },
]
function detailsNotAllowedForReason(reason?: InsuranceWithdrawalReason): boolean {
    switch (reason) {
        case InsuranceWithdrawalReason.OtherInsurance:
            return false
        case InsuranceWithdrawalReason.WrongProduct:
            return false
        case InsuranceWithdrawalReason.Other:
            return false
        default:
            return true
    }
}

const validationSchema = yup.object({
    reason: yup.string().required("Reason is required"),
    details: yup.string(),
    note: yup.string().required("Note is required"),
    notifications: yup.boolean(),
    effectiveDate: yup.date().required("Effective Date is required"),
})

const Withdrawal = () => {
    const { id } = useParams()
    assert(id)

    let navigate = useNavigate()
    const { t } = useTranslation()

    const { data, loading, error } = useQuery<InsuranceQuery, InsuranceQueryVariables>(insuranceGQL, { fetchPolicy: "network-only", variables: { id } })
    const [withdrawInsurance, { data: withdrawInsuranceData, error: withdrawInsuranceError }] = useMutation<
        WithdrawInsuranceAgreementMutation,
        WithdrawInsuranceAgreementMutationVariables
    >(withdrawInsuranceAgreementGQL)

    const formik = useFormik({
        initialValues: {
            reason: undefined,
            details: undefined,
            note: "",
            notifications: true,
        },
        validationSchema: validationSchema,
        onSubmit: async values => {
            if (!values.reason) {
                alert("Please select a reason")
            } else {
                const response = await withdrawInsurance({
                    variables: {
                        input: {
                            id,
                            internalNote: values.note,
                            notificationsEnabled: values.notifications ? values.notifications : false,
                            reason: values.reason,
                            reasonDetails: values.details,
                        },
                    },
                })
                if (response.data?.withdrawInsuranceAgreement.__typename === "WithdrawInsuranceAgreementOutputWithdrawn") {
                    navigate(`/insurance/${id}`)
                }
            }
        },
    })
    useEffect(() => {
        if (detailsNotAllowedForReason(formik.values.reason)) {
            if (formik.values.details) {
                formik.setFieldValue("details", "")
            }
        }
    }, [formik.values.reason])

    if (!data || error) {
        return <GraphQLError error={error} />
    }
    if (loading) {
        return <Loading />
    }
    const insurance = data.insurance
    if (!insurance) {
        return <p>{t("insuranceNotFound")}</p>
    }
    return (
        <div className="list-wrapper">
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <BreadCrumb name={t("insurance")} to={`/insurance/${id}`} />
            </div>
            <div className="form-item">
                <div className="mdc-list-item__graphic"></div>
                <WithdrawInsuranceHeader firstName={insurance.member.firstName} lastName={insurance.member.lastName} />
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <Select
                            outlined
                            className="text-field-width"
                            id="reason"
                            name="reason"
                            label="Reason key"
                            value={formik.values.reason}
                            onChange={e => formik.setFieldValue("reason", e.currentTarget.value)}
                            invalid={formik.touched.reason && Boolean(formik.errors.reason)}
                            options={InsuranceWithdrawalReasonArr}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            outlined
                            className="text-field-width"
                            disabled={detailsNotAllowedForReason(formik.values.reason)}
                            id="details"
                            name="details"
                            label="Reason details"
                            value={formik.values.details}
                            onChange={formik.handleChange}
                            invalid={formik.touched.details && Boolean(formik.errors.details)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <TextField
                            outlined
                            className="text-field-width"
                            id="note"
                            name="note"
                            label="Internal note"
                            textarea
                            value={formik.values.note}
                            onChange={formik.handleChange}
                            invalid={formik.touched.note && Boolean(formik.errors.note)}
                        />
                    </div>
                </div>
                <div className="form-item">
                    <div className="mdc-list-item__graphic"></div>

                    <div className="form-item-textfield">
                        <Checkbox
                            className="text-field-width"
                            id="notifications"
                            name="notifications"
                            label="Notifications"
                            checked={formik.values.notifications}
                            onChange={e => formik.setFieldValue("notifications", !!e.currentTarget.checked)}
                        />
                    </div>
                </div>
                <div className="button-center">
                    <Button label={t("confirm")} outlined type="submit" />
                </div>
                <TypeErrorForms
                    error={withdrawInsuranceData?.withdrawInsuranceAgreement.__typename === "WithdrawInsuranceAgreementOutputAgreementNotFound"}
                    errorMessage={t("WithdrawInsuranceAgreementOutputAgreementNotFound")}
                />
                <TypeErrorForms
                    error={withdrawInsuranceData?.withdrawInsuranceAgreement.__typename === "WithdrawInsuranceAgreementOutputAlreadyWithdrawn"}
                    errorMessage={t("WithdrawInsuranceAgreementOutputAlreadyWithdrawn")}
                />
                <TypeErrorForms
                    error={withdrawInsuranceData?.withdrawInsuranceAgreement.__typename === "WithdrawInsuranceAgreementOutputNotPossible"}
                    errorMessage={t("WithdrawInsuranceAgreementOutputNotPossible")}
                />
                <TypeErrorForms
                    error={withdrawInsuranceData?.withdrawInsuranceAgreement.__typename === "WithdrawInsuranceAgreementOutputNotificationsNotAllowed"}
                    errorMessage={t("WithdrawInsuranceAgreementOutputNotificationsNotAllowed")}
                />
                <TypeErrorForms
                    error={withdrawInsuranceData?.withdrawInsuranceAgreement.__typename === "WithdrawInsuranceAgreementOutputReasonDetailsNotAllowed"}
                    errorMessage={t("WithdrawInsuranceAgreementOutputReasonDetailsNotAllowed")}
                />
                <GraphQLError error={withdrawInsuranceError} />
            </form>
        </div>
    )
}

export default Withdrawal
