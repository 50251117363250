import React from "react"
import { Tooltip } from "@rmwc/tooltip"
import { Icon } from "@rmwc/icon"
import "@rmwc/tooltip/styles"
import "@rmwc/icon/styles"

export const CopyToolTip = (props: { name: string; children: React.ReactNode }) => {
    return (
        <Tooltip align="top" activateOn="click" leaveDelay={1000} content={`You copied ${props.name} into your clipboard`}>
            <>{props.children}</>
        </Tooltip>
    )
}

export const IconToolTip = ({ text, iconName, validityColors }: { text: string; iconName: string; validityColors?: boolean }) => {
    if (validityColors === undefined) {
        return (
            <Tooltip align="top" activateOn="hover" leaveDelay={200} enterDelay={0} content={text}>
                <Icon style={{ alignSelf: "flex-start" }} icon={iconName} />
            </Tooltip>
        )
    }
    if (validityColors === false) {
        return (
            <Tooltip align="top" activateOn="hover" leaveDelay={200} enterDelay={0} content={text}>
                <Icon style={{ color: "red", alignSelf: "flex-start" }} icon={iconName} />
            </Tooltip>
        )
    }
    return (
        <Tooltip align="top" activateOn="hover" leaveDelay={200} enterDelay={0} content={text}>
            <Icon style={{ color: "green", alignSelf: "flex-start" }} icon={iconName} />
        </Tooltip>
    )
}
