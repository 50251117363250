import { sortOrderType } from "../Dashboard/types"

export function sort(objectArray: any[], sortOrder: sortOrderType) {
    let array = [...objectArray]
    if (sortOrder.objectKey === "name") {
        if (sortOrder.order === 1) {
            return array.sort((a, b) => `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
        }
        if (sortOrder.order === -1) {
            return array.sort((a, b) => -1 * `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`))
        }
    } else {
        if (sortOrder.order === 1) {
            return array.sort((a, b) => a[sortOrder.objectKey].localeCompare(b[sortOrder.objectKey]))
        }
        if (sortOrder.order === -1) {
            return array.sort((a, b) => -1 * a[sortOrder.objectKey].localeCompare(b[sortOrder.objectKey]))
        }
    }
    return array
}

export const handleSort = (order: number | null, objectKey: string, setSortOrder: Function) => {
    const object: sortOrderType = {
        order: order,
        objectKey: objectKey,
    }
    setSortOrder(object)
}

export function returnSort(sortOrder: sortOrderType, objectKey: string) {
    if (sortOrder.objectKey === objectKey) {
        return sortOrder.order
    }
    return null
}
