import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from "@apollo/client"
import { onError } from "@apollo/client/link/error"
import ReactDOM from "react-dom"
import { useNavigate } from "react-router-dom"
import App from "./App"
import "./i18n"
import "./index.scss"
import reportWebVitals from "./reportWebVitals"

const logoutLink = onError((networkError: any) => {
    if (networkError?.networkError?.statusCode === 401) {
        let navigate = useNavigate()
        navigate("/login")
    }
})
const httpLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL + "/graphql",
    credentials: "include",
})
const client = new ApolloClient({
    link: logoutLink.concat(httpLink),
    cache: new InMemoryCache(),
})

// declare global {
//     const CF_PAGES_COMMIT_SHA: string
// }

ReactDOM.render(
    <ApolloProvider client={client}>
        <App />
    </ApolloProvider>,
    document.getElementById("root")
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
