import { Typography } from "@rmwc/typography";
import "@rmwc/typography/styles";
import { useTranslation } from "react-i18next";

const SyncMemberCasesHeader = ({ firstName, lastName }: { firstName: string; lastName: string }) => {
    const { t } = useTranslation()
    return (
        <>
            <Typography use="headline5">
                {`${t("sync")} `}
                <b>{`'${firstName}  ${lastName}'`}</b> {`${t("withDrWalter")}:`}
            </Typography>
        </>
    )
}
export default SyncMemberCasesHeader
