import { gql } from "@apollo/client"

export const insuranceGQL = gql`
query insurance($id: ID!) {
    insurance(id: $id) {
        id
        member {
            id
            firstName
            lastName
        }
        parameters {
            startDate
            endDate
            homeCountry {
                code
                name
            }
        }
        cancellations {
            id
            administrator {
                firstName
                lastName
            }
            effectiveDate
            reason
            notificationsEnabled
        }
    }
}
`
