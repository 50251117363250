import { Navigate } from "react-router-dom"
import Logout from "./Logout/Logout"
import GenkiTopBar from "./GenkiTopBar"
export default function AuthGuard({ children }: { children: JSX.Element }) {
    let cookieValue = document?.cookie
        ?.split("; ")
        ?.find(row => row.startsWith("accessExpirationTimestamp="))
        ?.split("=")[1]

    cookieValue = decodeURIComponent(cookieValue ? cookieValue : "")

    if (cookieValue) {
        const expiryDateLogOut = new Date(new Date(cookieValue).getTime() - 300000)
        const dateNow = new Date()

        if (+expiryDateLogOut <= +dateNow) {
            return <Logout />
        }

        return (
            <>
                <GenkiTopBar />
                {children}
            </>
        )
    } else {
        return <Navigate to="/login" />
    }
}
