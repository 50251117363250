import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { Typography } from "@rmwc/typography"

export const MemberSynced = ({ name }: { name: string }) => {
    const { t } = useTranslation()
    return (
        <div className="form-item">
            <div className="mdc-list-item__graphic"></div>
            <Typography className="error" use="headline5">
                {`🎉 ${name} ${t("isInSync")}`}
                <Link to="/?tab=drWalterSyncQueue">{t("dashboard")}</Link>?
            </Typography>
        </div>
    )
}
