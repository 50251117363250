import { DataTableHead, DataTableHeadCell, DataTableRow } from "@rmwc/data-table"
import { useTranslation } from "react-i18next"
import { handleSort, returnSort } from "../../shared/Sort"
import { sortOrderType } from "../types"

const InsuranceQuoteHeader = ({ sortOrder, setSortOrder }: { sortOrder: sortOrderType; setSortOrder: Function }) => {
    const { t } = useTranslation()

    return (
        <DataTableHead>
            <DataTableRow>
                <DataTableHeadCell
                    alignEnd
                    sort={returnSort(sortOrder, "name")}
                    onSortChange={sortDir => {
                        handleSort(sortDir, "name", setSortOrder)
                    }}
                >
                    {`${t("name")}`}
                </DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("emailAddress")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("hCountry")}</DataTableHeadCell>
                <DataTableHeadCell alignEnd>{t("type")}</DataTableHeadCell>
            </DataTableRow>
        </DataTableHead>
    )
}

export default InsuranceQuoteHeader
