const L404 =()=>{
    return(
        <>
        <h1>
            This page is in progress
        </h1>
        </>
    )
}

export default L404;